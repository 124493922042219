import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const evaluationFormRepository = RepositoryFactory.get('evaluationForm')

const state = {
  forms: [],
}

const actions = {
  async fetchStudentForms({commit}, params = {}) {
    const res = await evaluationFormRepository.reportStudentEvaluation({
      ...params,
      _populate: 'collectionTemplate',
    })
    commit('setStudentForm', res.data || [])
    return res.data || []
  },
}

const mutations = {
  setStudentForm(state, data) {
    state.forms = data
  },
}

const getters = {
  getForms: (state) => {
    return state.forms
      .reduce((result, current) => {
        const index = current.collectionTemplate.index
        if (!result[index]) {
          result[index] = []
        }
        result[index].push(current)
        return result
      }, [])
      .filter((v) => Array.isArray(v) && v.length)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
