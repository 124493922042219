import Repository from '@/api/core'
import queryString from 'query-string'
import vendor from '@/store/modules/vendor'
const baseURL = process.env.VUE_APP_API_LEARNING_PATH + '/relation-course'

const fetch = params => {
  const query = queryString.stringify({ ...params, vendorId: vendor.state.vendor.vendorId })
  return Repository(baseURL + `?${query}`).fetch()
}

export default {
  fetch,
}
