<template>
  <div></div>
</template>
<script>
  import {mapActions, mapGetters} from 'vuex'
  export default {
    data() {
      return {
        title: '',
        titleTemplate: '',
        siteName: '',
        faviconLink: '',
        domain: '',
        thumbnail: ''
      }
    },
    computed: {
      ...mapGetters({
        themeData: 'vendor/getThemedata',
        externalScript: 'vendor/getExternalScript'
      })
    },
    methods: {
      ...mapActions({
        updateDisplayTitle: 'seo/updateDisplayTitle',
        updateFavicon: 'seo/updateFavicon',
        updateMetaInfo: 'seo/updateMetaInfo',
        savePathLoadedSeo: 'seo/savePathLoadedSeo',
        writeExternalScript: 'seo/writeExternalScript'
      }),
      async executeSEO() {
        await this.initData()
        await this.updateDisplayTitle({
          title: this.title,
          titleTemplate: this.titleTemplate
        })
        await this.updateFavicon(this.faviconLink)
        this.setSeoMeta()
        this.savePathLoadedSeo()
      },
      initData() {
        this.siteName = this.$themeData.siteName
        this.title = this.$route.meta.title || 'Trang học'
        this.titleTemplate = this.siteName ? `${this.siteName} - %s` : null
        this.faviconLink = this.$themeData.favicon
        this.domain = window.location.hostname
        this.thumbnail = this.$themeData.thumbnail
      },
      setSeoMeta() {
        const params = {
          metaInfo: {
            title: this.title,
            domain: this.domain,
            url: this.domain,
            siteName: this.siteName,
            description: this.siteName,
            type: 'video_lecture',
            locale: 'vi_VN',
            image: this.thumbnail || this.faviconLink
          }
        }
        this.updateMetaInfo(params)
      },
      setExternalScript() {
        this.writeExternalScript(this.externalScript)
      }
    },
    watch: {
      $route: {
        handler() {
          this.executeSEO()
        },
        immediate: true
      },
      externalScript: {
        handler() {
          this.setExternalScript()
        },
        immediate: true
      }
    }
  }
</script>
