import vi from 'iztech-design-system-neo/dist/system/i18n/vi'
import ACTIVE_CODE from '@/i18n/vi/activeCode'
import AUTHENTICATION from '@/i18n/vi/authentication'
import BOOK from '@/i18n/vi/book'
import CERTIFICATE from '@/i18n/vi/certificate'
import CHECKOUT from '@/i18n/vi/checkout'
import COMMENT from '@/i18n/vi/comment'
import INPUT from '@/i18n/vi/input'
import LEARNING from '@/i18n/vi/learning'
import MENU from '@/i18n/vi/menu'
import MESSAGES from '@/i18n/vi/message'
import ONLINE_COURSE from '@/i18n/vi/onlineCourse'
import PAYMENT from '@/i18n/vi/payment'
import PROFILE from '@/i18n/vi/profile'
import RATING from '@/i18n/vi/rating'
import ZOOM from '@/i18n/vi/zoom'
export default {
  ...vi,
  ACTIVE_CODE,
  AUTHENTICATION,
  BOOK,
  CERTIFICATE,
  CHECKOUT,
  COMMENT,
  INPUT,
  LEARNING,
  MENU,
  MESSAGES,
  ONLINE_COURSE,
  PAYMENT,
  PROFILE,
  RATING,
  ZOOM,
  FILTER_BY_TOPIC_TITLE: "Chủ đề",
  BTN_VIEW: "Xem",
}