<template>
  <div>
    <ZoomDialog v-if="isSupportZoom" :loading="loading" :state="dialog" :zooms="data" />
    <div
      v-if="isSupportZoom"
      class="d-flex fixed cursor-pointer bg-white elevation-1 pa-2 rounded-pill"
      @click.stop="fetchZooms()"
    >
      <img style="width: 100px;" src="/learn-static/zoom.png" />
      <span class="red--circle"></span>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import ZoomDialog from '@/modules/Zoom/components/ZoomDialog.vue'
  import dayjs from 'dayjs'
  import uniqBy from 'lodash-es/uniqBy'
  export default {
    components: {ZoomDialog},
    data() {
      return {
        loading: false,
        dialog: false,
        data: []
      }
    },
    computed: {
      ...mapGetters({
        zooms: 'zoom/getZooms',
        isAuthenticated: 'auth/getAuthenticated',
        isDisplayZoom: 'vendor/getDisplayZoom',
        isPermissionAccessAllZoom: 'vendor/getPermissionAllZoom',
        authUser: 'auth/getAuthUser',
        timeServer: 'exam/timeServer'
      }),
      isSupportZoom() {
        const nameCurrentPage = this.$route.name
        return nameCurrentPage === 'Home' && this.isAuthenticated && this.isDisplayZoom
      }
    },
    methods: {
      ...mapActions({
        fetchGroup: 'userGroup/fetch',
        fetchZoom: 'zoom/fetch',
        fetchTimeServer: 'exam/fetchTimeServer'
      }),
      async fetchZooms() {
        this.loading = true
        this.dialog = !this.dialog
        await this.fetchTimeServer()
        const publishZoom = await this.fetchZoom({publish: true, _sort: 'createdAt:-1'})
        try {
          const listGroups = await this.fetchGroup({userId: this.authUser.id})
          const listGroupIds = listGroups.map(group => group.group)
          if (listGroupIds.length !== 0) {
            await this.fetchZoom({
              group_in: listGroupIds,
              _sort: 'createdAt:-1'
            })
          }
          this.loading = false
        } catch (err) {
          this.loading = false
          this.$message.error(err)
        }
        this.data = this.setZoomData(publishZoom)
      },
      setZoomData(publishZoom) {
        let data = this.zooms.concat(publishZoom)
        data = uniqBy(data, z => z.id)
        return data.filter(z => {
          return this.inDay(z.startTime, z.endTime)
        })
      },
      inDay(startTime, endTime) {
        const start = dayjs(startTime).valueOf()
        const end = dayjs(endTime).valueOf()
        const current = this.timeServer
        return current >= start && current <= end
      }
    }
  }
</script>
<style scoped>
  .fixed {
    right: 72px;
    bottom: 42px;
    position: fixed;
    z-index: 99;
    opacity: 0.85;
    box-shadow: 0px 8px 16px rgba(62, 62, 60, 0.24);
  }
  .red--circle {
    border-radius: 50%;
    background-color: red;
    width: 8px;
    height: 8px;
  }
  @media only screen and (max-width: 600px) {
    .fixed {
      right: 24px;
      bottom: 24px;
    }
  }
</style>
