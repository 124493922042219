import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_OFFLINE_STACK + '/attendance'

const fetchStudentReportAttendance = (params) => {
  return Repository(baseURL + '-student').fetch(params)
}

export default {
  fetch,
  fetchStudentReportAttendance,
}
