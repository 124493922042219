import axios from 'axios';
import auth from '@/store/modules/auth'
import { cipher, decipher } from '@/helpers/cipher.js'
import { authPopup } from '@/plugins/authPopup.js'
import { message } from '@/plugins/message.js'
const USER_BASE_URL = process.env.VUE_APP_API_STUDENT
const AUTH_USER_STORAGE_NAME = 'ol-credentials-auth-user'
const VENDOR_STORAGE_NAME = 'ol-amplify-vendorId-from-origin'
import get from 'lodash-es/get';
import router from '@/router'
import { checkInternetConnection } from '../../plugins/internet';
import { popup } from '@/plugins/popup.js'

let isRefreshing = false;
let failedQueue = [];


export default function setup() {
  axios.interceptors.request.use((config) => {
    if (!checkInternetConnection()) {
      popup.error('Vui lòng kiểm tra kết nối internet', { title: 'Lỗi kết nối' })
      return axios.isCancel;
    }
    const authUser = _loadAuthUser();
    const token = authUser ? authUser.accessToken : null;
    if (token) config.headers.Authorization = token;
    return config;
  }, (err) => { return Promise.reject(err); });

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (!checkInternetConnection()) return Promise.reject("Vui lòng kiểm tra kết nối internet");
      if (error.response.status !== 401) {
        const messageErr = get(error, 'response.data.data', null)
        const messageCode = get(error, 'response.data.code', null)
        if (messageErr && messageCode === 500) message.error(messageErr)
        return Promise.reject(error);
      } else {
        // kiểm tra trong queue còn request không, nếu còn thì không gọi refresh
        if (!failedQueue.length && !isRefreshing) {
          failedQueue.push(new Date().getTime())
          isRefreshing = true
          return getRefreshToken(error);
        }
      }
    });
}

function _loadAuthUser() {
  let authUserEncoded = localStorage.getItem(AUTH_USER_STORAGE_NAME)
  let vendorInfo = JSON.parse(localStorage.getItem(VENDOR_STORAGE_NAME))
  try {
    return authUserEncoded ? JSON.parse(decipher(vendorInfo.payload.vendorId)(authUserEncoded)) : null
  } catch (err) {
    return null
  }
}


function destroyToken() {
  failedQueue = []
  isRefreshing = false
  window.localStorage.setItem(AUTH_USER_STORAGE_NAME, null)
  auth.state.auth = null
}

async function getRefreshToken(error) {
  const authUser = _loadAuthUser()
  return axios
    .get(USER_BASE_URL + `/student/refresh/${authUser.id}`, {
      params: { refreshToken: authUser.refreshToken, vendorId: authUser.vendorId },
    })
    .then((response) => {
      auth.state.auth = {
        ...authUser,
        ...response.data
      }
      let authUserEncoded = cipher(authUser.vendorId)(JSON.stringify({ ...auth.state.auth }))
      window.localStorage.setItem(AUTH_USER_STORAGE_NAME, authUserEncoded)
      failedQueue = []
      isRefreshing = false
      return axios(error.response.config);
    })
    .catch((error2) => {
      const messageErr = get(error2, 'response.data.data', null)
      const messageCode = get(error2, 'response.data.code', null)
      if (messageCode === 113) {
        destroyToken()
        router.push('/')
        authPopup.show('login', true)
        message.error(messageErr)
      } else {
        message.error(messageErr)
      }
      return Promise.reject(error2);
    })
    .finally(setup);
}