import en from 'iztech-design-system-neo/dist/system/i18n/en'
import ACTIVE_CODE from '@/i18n/en/activeCode'
import AUTHENTICATION from '@/i18n/en/authentication'
import BOOK from '@/i18n/en/book'
import CERTIFICATE from '@/i18n/en/certificate'
import CHECKOUT from '@/i18n/en/checkout'
import COMMENT from '@/i18n/en/comment'
import INPUT from '@/i18n/en/input'
import LEARNING from '@/i18n/en/learning'
import MENU from '@/i18n/en/menu'
import MESSAGES from '@/i18n/en/message'
import ONLINE_COURSE from '@/i18n/en/onlineCourse'
import PAYMENT from '@/i18n/en/payment'
import PROFILE from '@/i18n/en/profile'
import RATING from '@/i18n/en/rating'
import ZOOM from '@/i18n/en/zoom'
export default {
  ...en,
  ACTIVE_CODE,
  AUTHENTICATION,
  BOOK,
  CERTIFICATE,
  CHECKOUT,
  COMMENT,
  INPUT,
  LEARNING,
  MENU,
  MESSAGES,
  ONLINE_COURSE,
  PAYMENT,
  PROFILE,
  RATING,
  ZOOM,
  FILTER_BY_TOPIC_TITLE: "Topic",
  BTN_VIEW: "View",
}