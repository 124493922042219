import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const OfflineClassMarkStudent = RepositoryFactory.get('offlineClassMarkStudent')

const state = {}

const actions = {
  async fetchMarkStudentReport(_, params) {
    const res = await OfflineClassMarkStudent.reportOneStudentMark(params)
    return res.data || []
  },
}

const mutations = {}

const getters = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
