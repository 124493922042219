<template>
  <div
    class="base__loading"
    :class="[
      fluid ? 'base__loading--fluid' : '',
      size ? `base__loading--${size}` : '',
      block ? `base__loading--block` : ''
    ]"
  >
    <DecoratorProgressCircular indeterminate :color="color" :size="actualSize" />
    <slot name="base__loading__text" />
  </div>
</template>

<script>
  export default {
    props: {
      fluid: Boolean,
      size: {type: String, default: 'md'},
      block: Boolean,
      color: {type: String, default: 'white'}
    },
    computed: {
      actualSize() {
        return {
          xs: 16,
          sm: 20,
          md: 24,
          lg: 32,
          xl: 40
        }[this.size]
      }
    }
  }
</script>

<style lang="scss">
  .base__loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    &.base__loading--block {
      display: block;
      text-align: center;
    }
  }
  .base__loading--fluid {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  .base__loading--lg {
    font-size: 60px;
  }
  /* Transparent Overlay */
  .base__loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
</style>
