import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_OFFLINE_EVALUATION + '/evaluation-form'
import queryString from 'query-string'

const reportStudentEvaluation = (params) => {
  const query = queryString.stringify({
    ...params,
  })
  return Repository(baseURL + '-student/publish' + `?${query}`).fetch()
}

export default {
  reportStudentEvaluation,
}
