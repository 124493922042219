import auth from '@/store/modules/auth'
import Repository from '@/api/core'
const BASE_URL = process.env.VUE_APP_API_GROUP + '/user-permission'

const fetch = params => {
  return Repository(BASE_URL).fetch(params)
}

const fetchOne = params => {
  return Repository(BASE_URL + `/${auth.state.auth.id}`).fetch(params)
}

export default {
  fetch,
  fetchOne
}
