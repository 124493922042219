import book from 'iztech-design-system-neo/dist/system/i18n/vi/book'

const SECTION_VIEW = {
  TITLE: 'Tài liệu: "{0}"',
  TITLE_SAME_TOPIC_LIST: "Tài liệu cùng chủ đề",
}

export default {
  ...book,
  SECTION_VIEW,
}