import book from 'iztech-design-system-neo/dist/system/i18n/en/book'

const SECTION_VIEW = {
  TITLE: 'Book: "{0}"',
  TITLE_SAME_TOPIC_LIST: "Book on the same Topic",
}

export default {
  ...book,
  SECTION_VIEW,
}