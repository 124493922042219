import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const RelationCourseRepository = RepositoryFactory.get('relationCourse')

const state = {
  relations: []
}
const actions = {
  async fetchRelationCourses({ commit }, params = {}) {
    const res = await RelationCourseRepository.fetch(params)
    commit('setRelationCourses', res.data || [])
    return res.data
  }
}
const mutations = {
  setRelationCourses(state, relations) {
    state.relations = relations
  }
}
const getters = {
  getRelationCourses(state) {
    return state.relations
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
