<template>
	<div>Đây là cái nút</div>
</template>
<script>
export default {
	created() {},
	data() {
		return {}
	},
}
</script>
