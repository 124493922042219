const ERROR = {
	SYSTEM: 'システムエラー',
	UNEXPECTED: 'エラーが発生しました。後でお試しください。!',
	NETWORK: "インターネットの接続が不安定になっています。ネットワーク接続を再確認してください!",
}

const POPUP_DEFAULT_TITLE = {
	SUCCESS: '成功のお知らせ',
	ERROR: 'エラーのお知らせ',
	WARNING: '警告',
	PROCEED: 'よろしいでしょうか？',
}

export default {
	ERROR,
	POPUP_DEFAULT_TITLE,
	// cart
	PRODUCT_EXIST_IN_CART: 'このコースは既にカートに入っています。',
	ADD_TO_CART_SUCCESS: 'カートに正常に追加されました。',
	// loading
	UPDATING: '更新中...',
	LOADING: 'ただいま更新中です。',
	// error when trying to get suitable data
	CANNOT_FIND_PDF_TRY_AGAIN: 'PDFファイルのパスが見つかりません。もう一度お試しください。',
	CANNOT_FIND_MIME_TYPE: '一一致するファイル形式が見つかりません!',
	// section form submit
	FORM_SUBMIT_SUCCESS: '私たちはあなたの情報を受け取りました',
	FORM_SUBMIT_ERROR: '提出された情報は失敗しました。 テクニカルサポートにお問い合わせください。',
}
