import VueFbCustomerChat from 'vue-fb-customer-chat'
import Vue from 'vue'

export default function setup() {
  const fbPage = localStorage.getItem('facebookPageId')
  if (fbPage) {
    Vue.use(VueFbCustomerChat, {
      page_id: String(fbPage),
      theme_color: '#333333',
      locale: 'en_US',
    })
  }

}