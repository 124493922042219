import UserPermission from '@/api/repository/userPermissionRepository'

const state = {
  userPermissions: [],
  userPermissionsBook: [],
  permissionAccessCourse: {},
  permissionAccessBook: {},
  userPermissionMembership: {},
  userPermissionOffline: [],
}

const actions = {
  async fetchUserPermissions({ commit }, userId) {
    const res = await UserPermission.fetch({ userId })
    const filterOnlineCourse = res.data.onlineCourses
      .filter((i) => !i.isExpired)
      .map((i) => {
        return {
          ...i,
          authors: i.authors.map((i) => {
            return { ...i, fullname: i.name }
          }),
        }
      })
    const filterOfflineCourse = res.data.offlineCourses
      .filter((i) => !i.isExpired)
      .map((i) => {
        return {
          ...i,
          authors: i.authors.map((i) => {
            return { ...i, fullname: i.name }
          }),
          price: {
            // todo: phải có cái này mới hiển thị nút đc
            afterDiscount: 0,
          },
        }
      })
    commit('setUserPermissionsCourse', filterOnlineCourse)
    commit(
      'setUserPermissionsBook',
      res.data.books
        .filter((i) => !i.isExpired)
        .map((i) => {
          return {
            ...i,
            // todo: phải map như này mới hiển thị đc ảnh trong design system
            previewImage: {
              origin: {
                link: i.previewImg,
              },
            },
          }
        })
    )
    commit('setUserPermissionOffline', filterOfflineCourse)
    return res.data
  },
  async checkPermissionAccessCourse({ commit, rootState }, courseId) {
    const listOwnedCourse = rootState.userPermission.userPermissions
    const existed = listOwnedCourse.find((i) => i.id === courseId)
    commit('setPermissionAccessCourse', existed)
  },
  async checkPermissionAccessBook({ commit, rootState }, bookId) {
    const listOwnedBook = rootState.userPermission.userPermissionsBook
    const existed = listOwnedBook.find((i) => i.id === String(bookId))
    commit('setPermissionAccessBook', existed)
    return existed
  },
}

const mutations = {
  setUserPermissionsCourse(state, userPermissions) {
    state.userPermissions = userPermissions
  },
  setUserPermissionsBook(state, userPermissionsBook) {
    state.userPermissionsBook = userPermissionsBook
  },
  setPermissionAccessCourse(state, permissionAcessCourse) {
    state.permissionAccessCourse = permissionAcessCourse
  },
  setPermissionAccessBook(state, permissionAcessBook) {
    state.permissionAccessBook = permissionAcessBook
  },
  setUserPermissionOffline(state, data) {
    state.userPermissionOffline = data
  },
}

const getters = {
  getUserPermissions: (state) => {
    return state.userPermissions
  },
  getUserPermissionsBook: (state) => {
    return state.userPermissionsBook
  },
  getPermissionAccessCourse: (state) => {
    return state.permissionAccessCourse
  },
  getPermissionAccessBook: (state) => {
    return state.permissionAccessBook
  },
  getUserPermissionOffline: (state) => {
    return state.userPermissionOffline
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
