/*eslint-disable */
import {Auth} from '@/api/repository/authRepository.js'
import axios from 'axios'
import {fromBuffer} from 'file-type'

async function getMimeType(file) {
  const fileToArrayBuffer = await file.arrayBuffer()
  const fileType = await fromBuffer(fileToArrayBuffer)
  return fileType.mime
}

/**
 * Function upload video lên vimeos. sử dụng tus method để hỗ trợ upload và retry
 * @return: Promise
 */
async function uploadFile(file, link, onProgress) {
  const contentType = await getMimeType(file)
  if (contentType) {
    const params = {key: link, contentType}
    let signedUrl = await Auth.getS3SignedToken(params)
    const uninterceptedAxiosInstance = axios.create()
    const correctedFile = new Blob([file], {type: contentType})
    return await uninterceptedAxiosInstance.put(signedUrl, correctedFile, {
      onUploadProgress: function (progressEvent) {
        let {loaded, total} = progressEvent
        onProgress({
          loaded,
          total,
          percent: (loaded / total) * 100,
        })
      },
      headers: {'Content-Type': contentType},
    })
  } else {
    throw new Error('$vuetify.MESSAGES.CANNOT_FIND_MIME_TYPE')
  }
}

export default {uploadFile, getMimeType}
