import jp from 'iztech-design-system-neo/dist/system/i18n/jp'
import ACTIVE_CODE from '@/i18n/jp/activeCode'
import AUTHENTICATION from '@/i18n/jp/authentication'
import BOOK from '@/i18n/jp/book'
import CERTIFICATE from '@/i18n/jp/certificate'
import CHECKOUT from '@/i18n/jp/checkout'
import COMMENT from '@/i18n/jp/comment'
import INPUT from '@/i18n/jp/input'
import LEARNING from '@/i18n/jp/learning'
import MENU from '@/i18n/jp/menu'
import MESSAGES from '@/i18n/jp/message'
import ONLINE_COURSE from '@/i18n/jp/onlineCourse'
import PAYMENT from '@/i18n/jp/payment'
import PROFILE from '@/i18n/jp/profile'
import RATING from '@/i18n/jp/rating'
import ZOOM from '@/i18n/jp/zoom'
export default {
  ...jp,
  ACTIVE_CODE,
  AUTHENTICATION,
  BOOK,
  CERTIFICATE,
  CHECKOUT,
  COMMENT,
  INPUT,
  LEARNING,
  MENU,
  MESSAGES,
  ONLINE_COURSE,
  PAYMENT,
  PROFILE,
  RATING,
  ZOOM,
  FILTER_BY_TOPIC_TITLE: "トピック",
  BTN_VIEW: "見る",
}