import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const LearningPathRepository = RepositoryFactory.get('learningPath')
import utils from '@/helpers/utils.js'

const state = {
  learningPaths: [],
  learningPath: {}
}
const actions = {
  async fetchLearningPaths({ commit }, params = {}) {
    const res = await LearningPathRepository.fetch(params)
    commit('setLearningPaths', res.data || [])
    return res.data
  },
  async fetchLearningPath({ commit }, id) {
    const res = await LearningPathRepository.fetchOne(id)
    commit('setLearningPath', res.data || {})
    return res.data
  },
  async fetchLearningPathsIndependent(_, params = {}) {
    const res = await LearningPathRepository.fetch(params)
    return res.data || []
  },
}
const mutations = {
  setLearningPaths(state, learningPaths) {
    state.learningPaths = learningPaths.map(utils.mutateDataWithRating)
  },
  setLearningPath(state, learningPath) {
    let courses = (learningPath.courses || []).map(course => {
      let courseWithRating = utils.mutateDataWithRating(course)
      return {...courseWithRating, ...courseWithRating.course}
    })
    const pathData = {...learningPath, courses}
    state.learningPath = pathData
  },
}
const getters = {
  getLearningPaths(state) {
    return state.learningPaths
  },
  getLearningPath(state) {
    return state.learningPath
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}