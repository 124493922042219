const POPUP = {
  TITLE: 'Online Class',
  CTA_BTN: {
    JOIN: 'Join',
    IS_FINISHED: 'Finished',
  },
  EMPTY_DESCRIPTION: "There isn't any available Zoom class",
}

export default {
  POPUP,
}