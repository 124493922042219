<template>
  <v-dialog v-model="contactPopup.isShow" width="400" :persistent="contactPopup.isPersistent">
    <div style="background-color: #ffffff">
      <ContactInfoForm
        :isAuthenticated="!!userProfile"
        :user-profile="
          userProfile && {
            name: userProfile.name,
            email: userProfile.email,
            phone: userProfile.phone,
          }
        "
        @onCreateOrder="createContactRequest"
        @onRequestSignIn="$authPopup.show('login')"
      />
    </div>
  </v-dialog>
</template>
<script>
  import {mapGetters, mapActions} from 'vuex'
  export default {
    data() {
      return {
        contactPopup: this.$contactPopup.contactPopupData,
        authPopup: this.$authPopup.authPopupData,
      }
    },
    computed: {
      ...mapGetters({
        isDisableRegistration: 'vendor/getIsDiableRegistration',
        userProfile: 'auth/getAuthUser',
        courseOnline: 'onlineCourse/getCourse',
        courseOffline: 'offlineCourse/getCourseById',
      }),
    },
    methods: {
      ...mapActions({
        createContact: 'cart/createContact',
      }),
      async createContactRequest(data) {
          this.$message.loading(this.$vuetify.lang.t('$vuetify.MESSAGES.LOADING'), 20000)
          try {
            const courseDetail = this.contactPopup.state === 'course-online' ? this.courseOnline : this.courseOffline
            await this.createContact({
              customerEmail: data.email,
              customerName: data.name,
              customerPhone: data.phone,
              items: [
                {
                  id: courseDetail.id,
                  type: 'course',
                  title: courseDetail.title,
                },
              ],
              purchasingProvider: '',
            })
            this.$message.close()
            this.$message.success('Đăng ký nhận tư vấn thành công!')
          } catch (error) {
            const errorData = JSON.parse(JSON.stringify(error.response.data))
            let listItems = JSON.parse(errorData.message)
            let listNameItemsExist = listItems.map((item) => item.title)
            if (listItems && listItems.length) this.$message.error(`${errorData.name} ${listNameItemsExist.join(',')}`)
          }
          this.$contactPopup.close()
        }
      },
  }
</script>
