import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_EXAM + '/submission'
const rankingRedisURL = process.env.VUE_APP_API_LEADERBOARD + '/submission'
import queryString from 'query-string'
import vendor from '@/store/modules/vendor'
const fetch = (params) => {
  const query = queryString.stringify({ ...params, vendorId: vendor.state.vendor.vendorId })
  return Repository(baseURL + `?${query}`).fetch()
}
const count = (params) => {
  return Repository(baseURL + '/count').fetch(params)
}
const fetchLast = (params) => {
  return Repository(baseURL + '/last-submission').fetch(params)
}

const updateViolations = (id, params) => {
  return Repository(baseURL + '/update-violations').update(id, params)
}

const fetchOne = (id) => {
  return Repository(baseURL).fetchOne(id)
}

const create = (params) => {
  return Repository(baseURL).create(params)
}

const update = (id, params) => {
  return Repository(baseURL).update(id, params)
}

const submit = (id, params) => {
  return Repository(rankingRedisURL + '/submit').update(id, params)
}

export default {
  fetch,
  fetchOne,
  create,
  update,
  fetchLast,
  updateViolations,
  submit,
  count,
}
