const ERROR = {
	SYSTEM: 'System Error',
	UNEXPECTED: 'Error. Please try again!',
	NETWORK: "Your network is unstable. Please re-check your network!",
}

const POPUP_DEFAULT_TITLE = {
	SUCCESS: 'Success',
	ERROR: 'Error',
	WARNING: 'Warning',
	PROCEED: 'Are you sure?',
}

export default {
	ERROR,
	POPUP_DEFAULT_TITLE,
	// cart
	ADD_TO_CART_SUCCESS: 'Add to cart successfully!',
	PRODUCT_EXIST_IN_CART: 'This product is already existed in cart!',
	// loading
	UPDATING: 'Updating...',
	LOADING: 'Loading...',
	// error when getting suitable data
	CANNOT_FIND_PDF_TRY_AGAIN: 'Cannot find PDF file URL! Please try again.',
	CANNOT_FIND_MIME_TYPE: 'Cannot find suitable file type!',
	// section form submit
	FORM_SUBMIT_SUCCESS: 'We have received your information',
	FORM_SUBMIT_ERROR: 'Information submitted failed. Please contact to our technical suport.',
}
