import BaseContactPopup from '@/global/BaseContactPopup.vue'
const contactPopup = {
  contactPopupData: {
    isShow: false,
    state: '',
    isLoading: false,
    isPersistent: false,
  },
  close() {
    Object.assign(this.contactPopupData, {isShow: false, isLoading: false})
  },
  show(state, isPersistent = false) {
    Object.assign(this.contactPopupData, {isShow: true, state, isPersistent})
  },
  setLoading(val) {
    Object.assign(this.contactPopupData, {isLoading: val})
  },
}

export default {
  install(Vue, pluginName = '$contactPopup') {
    Vue.prototype[pluginName] = contactPopup
    Vue.component('plugin-contact-popup', BaseContactPopup)
  },
}

export {contactPopup}
