import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const UserCourseRepository = RepositoryFactory.get('userCourse')
const defaultState = () => {
  return {
    userCourse: null,
  }
}
const state = defaultState()

const actions = {
  async enrollUserCourse({commit}, data) {
    const userCourse = await UserCourseRepository.enroll(data)
    commit('setUserCourse', userCourse.data)
    return userCourse.data
  },
  async count(_, params) {
    const result = await UserCourseRepository.count(params)
    return result.data
  },
  async fetchUserCourse({rootState, commit}, {courseId}) {
    if (rootState.auth.authenticated) {
      let data = await UserCourseRepository.fetch({
        userId: rootState.auth.auth.id,
        course: courseId,
      })
      commit('setUserCourse', data.data && data.data.length ? data.data[0] : {})
      return data.data
    }
  },

  async interactionQrCode(_, {unitId, userId}) {
    const res = await UserCourseRepository.interactionQrCode({unitId: unitId, userId: userId})
    return res.data
  },
  async markCompleteLesson({commit}, {unitId, isFinished}) {
    const userCourse = await UserCourseRepository.markComplete({unitId: unitId, isFinished})
    commit('setUserCourse', userCourse.data)
    return userCourse.data
  },
  async logProgress({commit}, params) {
    const userCourse = await UserCourseRepository.logProgress(params)
    commit('setUserCourse', userCourse.data)
    return userCourse.data
  },
  async interactUserCourse({commit}, {id, userCourse}) {
    const res = await UserCourseRepository.interact(id, userCourse)
    commit('setUserCourse', res.data)
    return res.data
  },
}

const mutations = {
  setUserCourse(state, userCourse) {
    state.userCourse = userCourse
  },
  reset(state){
    Object.assign(state, defaultState())
  }
}

const getters = {
  getUserCourse: (state) => {
    return state.userCourse
  },
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true,
}
