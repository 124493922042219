const notify = {
	token: '',
	setToken(token) {
		this.token = token
	},
	getToken() {
		return this.token
	}
}

export default 
	notify


export {notify}
