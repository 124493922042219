/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import authFilter from '@/router/authFilter'

Vue.use(VueRouter)
Vue.use(Meta)
function lazyLoad(view) {
  return () => import(`@/pages/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: lazyLoad('Home'),
    meta: {
      title: 'Trang chủ'
    }
  },
  {
    path: '/view-profile',
    name: 'ViewProfile',
    beforeEnter: authFilter,
    component: lazyLoad('ViewProfile'),
    meta: {
      title: 'Thông tin cá nhân'
    }
  },
  {
    path: '/active-course',
    name: 'ActiveCode',
    component: lazyLoad('ActiveCode'),
    meta: {
      title: 'Kích hoạt khoá học'
    }
  },
  {
    path: '/purchased',
    name: 'MyLearning',
    beforeEnter: authFilter,
    component: lazyLoad('MyLearning'),
    meta: {
      title: 'Khoá học của tôi'
    }
  },
  {
    path: '/department',
    name: 'DepartmentList',
    component: lazyLoad('DepartmentList'),
    meta: {
      title: 'Danh sách cơ sở'
    }
  },
  {
    path: '/checkoutCart',
    name: 'Checkout',
    component: lazyLoad('Checkout'),
    meta: {
      title: 'Đơn hàng và thanh toán'
    }
  },
  {
    path: '/purchase-status',
    name: 'Trạng thái thanh toán',
    component: lazyLoad('PurchaseStatus'),
    meta: {
      title: 'Trạng thái thanh toán'
    }
  },
  {
    path: '/payment/:type/:id',
    name: 'Payment detail',
    component: lazyLoad('Payment'),
    meta: {
      title: 'Thanh toán khóa học'
    }
  },
  {
    path: '/course-online-detail/:id',
    name: 'course-online-detail',
    component: lazyLoad('DetailCourseOnline')
  },
  {
    path: '/course-online',
    name: 'OnlineCourseList',
    component: lazyLoad('OnlineCourseList'),
    meta: {
      title: 'Danh sách khóa học online'
    }
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: lazyLoad('About'),
  //   meta: {
  //     title: 'Về chúng tôi'
  //   }
  // },
  {
    path: '/about-us',
    name: 'About Us',
    component: lazyLoad('AboutUs'),
    meta: {
      title: 'Về chúng tôi'
    }
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: lazyLoad('PrivacyPolicy'),
    meta: {
      title: 'Chính sách và bảo mật'
    }
  },
  {
    path: '/recruitment',
    name: 'Recruitment',
    component: lazyLoad('Recruitment'),
    meta: {
      title: 'Trở thành giảng viên'
    }
  },
  {
    path: '/confirm-sign-up',
    name: 'ConfirmSignUp',
    component: lazyLoad('ConfirmSignUp'),
    meta: {
      title: 'Xác thực đăng ký'
    }
  },
  {
    path: '/teacher-detail/:id',
    name: 'TeacherDetail',
    component: lazyLoad('TeacherDetail'),
    meta: {
      title: 'Thông tin chi tiết giáo viên'
    }
  },
  {
    path: '/course-offline/:id',
    name: 'offline-course-detail',
    component: lazyLoad('OfflineCourseDetail')
  },
  {
    path: '/course-online-learning/:courseId/:sectionId/:unitId',
    name: 'course-online-learning',
    beforeEnter: authFilter,
    component: lazyLoad('Learning'),
    meta: {
      title: 'Trang học'
    }
  },
  {
    path: '/course-online-learning-alpha/:courseId/:sectionId/:unitId',
    name: 'course-online-learning-alpha',
    beforeEnter: authFilter,
    component: lazyLoad('LearningAlpha'),
    meta: {
      title: 'Trang học beta'
    }
  },
  {
    path: '/course-offline/',
    name: 'OfflineCourse',
    component: lazyLoad('OfflineCourseList'),
    meta: {
      title: 'Danh sách khóa học Offline'
    }
  },
  {
    path: '/event-list/',
    name: 'Event',
    component: lazyLoad('EventList'),
    meta: {
      title: 'Danh sách các sự kiện'
    }
  },
  {
    path: '/event-detail/:id',
    name: 'eventDetail',
    component: lazyLoad('EventDetail'),
    meta: {
      title: 'Chi tiết sự kiện'
    }
  },
  {
    path: '/book-list/',
    name: 'Ebook',
    component: lazyLoad('Ebook'),
    meta: {
      title: 'Danh sách tài liệu điện tử'
    }
  },
  {
    path: '/notification',
    name: 'Thông báo',
    component: lazyLoad('NotificationList'),
    meta: {
      title: 'Danh sách thông báo'
    }
  },
  {
    path: '/ebook-detail/:id',
    name: 'EbookDetail',
    component: lazyLoad('EbookDetail')
  },
  {
    path: '/view-book/:id',
    name: 'ViewBook',
    component: lazyLoad('ViewBook')
  },
  {
    path: '/blog-detail/:id',
    name: 'BlogDetail',
    component: lazyLoad('BlogDetail'),
    meta: {
      title: 'Chi tiết bài viết'
    }
  },
  {
    path: '/blog',
    name: 'BlogList',
    component: lazyLoad('BlogList'),
    meta: {
      title: 'Danh sách các bài viết'
    }
  },
  {
    path: '/certificate-detail/:id',
    name: 'CertificateDetail',
    beforeEnter: authFilter,
    component: lazyLoad('CertificateDetail'),
    meta: {
      title: 'Chứng chỉ của tôi'
    }
  },
  {
    path: '/sign-in-via-link',
    name: 'sign-in-via-link',
    component: lazyLoad('SignInViaLink'),
    meta: {
      title: 'Đăng nhập bằng link'
    }
  },
  {
    path: '/submit-via-link',
    name: 'submit-via-link',
    component: lazyLoad('SubmitViaLink'),
    meta: {
      title: 'Nộp bài tập qua link'
    }
  },
  {
    path: '/learning-path',
    name: 'LearningPathList',
    component: lazyLoad('LearningPathList'),
    meta: {
      title: 'Danh sách lộ trình học'
    }
  },
  {
    path: '/learning-path-detail/:id',
    name: 'LearningPathDetail',
    component: lazyLoad('LearningPathDetail'),
    meta: {
      title: 'Chi tiết lộ trình học'
    }
  },
  {
    path: '*',
    name: 'not-found',
    component: lazyLoad('NotFound'),
    meta: {
      title: 'Không tìm thấy trang'
    }
  },
  {
    path: '/membership',
    name: 'Membership',
    component: lazyLoad('Membership'),
    meta: {
      title: 'Thẻ thành viên'
    }
  },
  {
    path: '/combo',
    name: 'Combo',
    component: lazyLoad('Combo'),
    meta: {
      title: 'Combo'
    }
  },
  {
    path: '/combo/:id',
    name: 'ComboDetail',
    component: lazyLoad('ComboDetail'),
    meta: {
      title: 'Chi tiết combo'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 250) // Timout delay set to match animation duration of from-page
    })
  },
  routes
})

export default router
