import queryString from 'query-string'
import Repository from '@/api/core'
import vendor from '@/store/modules/vendor'
const baseURL = process.env.VUE_APP_API_RATING + '/reaction'

const count = params => {
  return Repository(baseURL).count(params)
}
const fetch = params => {
  const query = queryString.stringify(params)
  return Repository(baseURL + `-mine?${query}`).fetch()
}
const fetchOne = id => {
  return Repository(baseURL).fetchOne(id)
}
const create = params => {
  return Repository(baseURL).create(params)
}
const update = (id, params) => {
  return Repository(baseURL).update(id, params)
}
const remove = id => {
  return Repository(baseURL).delete(id)
}
const removeMine = params => {
  return Repository(baseURL + `-mine?vendorId=${vendor.state.vendor.vendorId}`).create(params)
}

export default {
  fetch,
  fetchOne,
  create,
  update,
  count,
  remove,
  removeMine
}
