var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      attrs: {
        bottom: _vm.y === "bottom",
        color: _vm.message.color,
        elevation: 0,
        left: _vm.x === "left",
        "multi-line": _vm.mode === "multi-line",
        right: _vm.x === "right",
        timeout: _vm.message.time || 3000,
        top: _vm.y === "top",
        vertical: _vm.mode === "vertical",
      },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    staticClass: "mr-2",
                    attrs: { elevation: "0", dark: "", icon: "" },
                    on: { click: _vm.close },
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.message.isDisplay,
        callback: function ($$v) {
          _vm.$set(_vm.message, "isDisplay", $$v)
        },
        expression: "message.isDisplay",
      },
    },
    [
      _c(
        "div",
        { staticClass: "subtitle-1" },
        [
          _vm.message.color === "grey darken-2"
            ? _c("v-progress-circular", {
                staticClass: "mr-3",
                attrs: {
                  width: 3,
                  size: 30,
                  indeterminate: "",
                  color: "white",
                },
              })
            : _vm._e(),
          _vm._v(" " + _vm._s(_vm.translateMessage(_vm.message.text)) + " "),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }