import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_OFFLINE_STACK + '/class-student'

const fetchClassStudent = (params) => {
  return Repository(baseURL).fetch(params)
}

const addStudent = (params) => {
  return Repository(baseURL).create(params)
}

const deleteStudent = (id) => {
  return Repository(baseURL).delete(id)
}


export default {
  addStudent,
  fetchClassStudent,
  deleteStudent,
}
