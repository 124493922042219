import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const Student = RepositoryFactory.get('student')
const namespaced = true

const state = {
  profile: {}
}

const actions = {
  async fetchStudent({ commit }, id) {
    const res = await Student.fetchOne(id)
    commit('setProfile', res.data || {})
    return res.data
  },
  setStudent({commit}, params = {}) {
    commit('setProfile', params)
  }
}

const mutations = {
  setProfile(state, profile) {
    return (state.profile = profile)
  }
}

const getters = {
  getStudent: state => {
    return state.profile
  }
}

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
}
