import { Auth } from '@/api/repository/authRepository.js'
import Base64 from '@/helpers/encodeBase64.js'
import { cipher, decipher } from '@/helpers/cipher.js'
import { notify } from '@/plugins/notify.js'

const AUTH_USER_STORAGE_NAME = 'ol-credentials-auth-user'

const state = {
  auth: null,
  authenticated: false,
}
const actions = {
  async initAuthen({ dispatch, rootState }) {
    const vendorId = rootState.vendor.vendor.vendorId
    let authUser = _loadAuthUser(vendorId)
    if (authUser) dispatch('setAuth', authUser)
    else dispatch('signOut')
  },
  async signIn({ dispatch, rootState }, payload) {
    const currentVendorId = rootState.vendor.vendor.vendorId
    if (!payload.email || !payload.password)
      throw new Error(`[Auth.SignIn] 'username' & 'password' && 'password' required!`)
    let body = {
      email: payload.email.trim().toLowerCase(),
      password: Base64.encode(payload.password),
      vendorId: currentVendorId
    }
    const res = await Auth.signIn(body)
    Auth.subcribeNotify(
      {
        userId: res.id,
        vendorId: res.vendorId,
        deviceToken: notify.token
      }
    ).then()
    await dispatch('setAuth', res)
    return res
  },
  async signInWithAccessToken({ dispatch, rootState }, accessToken) {
    const currentVendor = rootState.vendor.vendor
    if (!accessToken) throw new Error(`[Auth.SignIn] 'accessToken' required!`)
    const res = await Auth.signInWithAccessToken(accessToken, currentVendor.vendorId)
    await dispatch('setAuth', res)
    return res
  },
  async signUp({ rootState }, userInfo) {
    const currentVendor = rootState.vendor.vendor
    const userInfoHashPassword = {
      ...userInfo,
      email: userInfo.email.trim().toLowerCase(),
      password: Base64.encode(userInfo.password),
      createdFrom: 'student'
    }
    return await Auth.signUp(userInfoHashPassword, currentVendor.vendorId, currentVendor.clientId)
  },
  async changePassword({ rootState, state }, payload) {
    const currentVendor = rootState.vendor.vendor
    const userInfoPassword = {
      userId: state.auth.id,
      oldPassword: Base64.encode(payload.oldPassword),
      newPassword: Base64.encode(payload.newPassword)
    }
    return await Auth.changePassword(userInfoPassword, currentVendor)
  },
  async sendCodeForgotPassword({ rootState }, payload) {
    const currentVendor = rootState.vendor.vendor
    return await Auth.sendCodeForgotPassword({
      email: payload,
      vendorId: currentVendor.vendorId
    })
  },
  async submitForgotPassword({ rootState }, payload) {
    const currentVendor = rootState.vendor.vendor
    const data = {
      email: payload.email,
      confirmCode: payload.code,
      newPassword: Base64.encode(payload.password)
    }
    return await Auth.submitForgotPassword(data, currentVendor)
  },
  async signOut({ commit }) {
    commit('setAuth', null)
    _removeAuthUser()
  },
  async refreshToken({ state, dispatch }, authUser) {
    if (!state.auth) return
    let newTokens = null
    newTokens = await Auth.refreshToken(authUser)
    const newAuthUser = {
      ...authUser,
      ...newTokens
    }
    dispatch('setAuth', newAuthUser)
    return newAuthUser
  },
  saveAuthuser(_, authUser) {
    _saveAuthUser(authUser, authUser.vendorId)
  },
  setAuth({ commit }, authuser) {
    commit('setAuth', authuser)
    commit('setAuthenticated', !!authuser)
    if (authuser) _saveAuthUser(authuser, authuser.vendorId)
  }
}
const mutations = {
  setAuth(state, auth) {
    state.auth = auth
  },
  setAuthenticated(state, val) {
    state.authenticated = val
  }
}
const getters = {
  getAuthUser: state => state.auth,
  getAuthenticated: state => state.authenticated
}

// helpers

function _saveAuthUser(authUser, vendorId) {
  let authUserEncoded = cipher(vendorId)(JSON.stringify({ ...authUser }))
  return window.localStorage.setItem(AUTH_USER_STORAGE_NAME, authUserEncoded)
}

function _removeAuthUser() {
  return window.localStorage.removeItem(AUTH_USER_STORAGE_NAME)
}

function _loadAuthUser(vendorId) {
  let authUserEncoded = localStorage.getItem(AUTH_USER_STORAGE_NAME)
  try {
    return authUserEncoded ? JSON.parse(decipher(vendorId)(authUserEncoded)) : null
  } catch (err) {
    return null
  }
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
