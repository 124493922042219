import input from 'iztech-design-system-neo/dist/system/i18n/jp/input'

export default {
  ...input,
  RULES: {
    ...input.RULES,
    NO_WHITESPACES: "スペースがありません。",
    PERCENTAGE: "値は100未満で0より大きい必要があります。",
    AT_LEAST_ONE: "少なくとも1文字あります。",
    MAX_LENGTH: "最大長は{0}です。",
    MIN_LENGTH: "最小長は{0}です。",
    URL: "パスのフォーマットが不正です。",
    NUMBER: "数字のフォーマットが不正です。",
    VALID_PRICE: "ペイメントゲートウェイで1万ドン未満の取引を行えません。より高い金額の取引を実施してください。",
    NUMBER_GREATER_EQUAL: "{0}以上の数字です。",
    NUMBER_GREATER_THAN: "{0}より大きい数字です。",
    NUMBER_LESS_THAN: "{0}未満の数字です。",
    NUMBER_CLAMP: "{0}から{1}までの数字です。",
    DATE_GREATER_THAN: "選択した日付は{0}日より後である必要があります。",
    DOMAIN: "ドメイン名のフォーマットが不正です。",
  }
}