import get from 'lodash-es/get'
import dayjs from 'dayjs'

const isAndroid = () => {
  const uA = navigator.userAgent.toLowerCase()
  return uA.indexOf('android') > -1
}

const isSafari = () => {
  const uA = navigator.userAgent.toLowerCase()
  const vendor = navigator.vendor
  if (/Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA)) {
    return true
  }
  return navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)
}

const isIphone = () => {
  return navigator.userAgent.match(/(iPod|iPhone|iPad)/)
}

const isImage = extension => {
  return /\.(gif|jpe?g|tiff?|png|webp|bmp)/i.test(`.${extension}`)
}

const validateTimesWatchVideo = (authuser, currentLesson, conditionWatchVideo) => {
  let data = JSON.parse(window.localStorage.getItem('exceed-times-watch-video'))
  let lesson = authuser ? get(data, `${authuser.id}.${currentLesson.id}`, null) : null
  if (conditionWatchVideo && conditionWatchVideo.isCountExceedWatchVideo) {
    const countExceedWatchVideo = conditionWatchVideo.countExceedWatchVideo
    if (countExceedWatchVideo && lesson) return Number(countExceedWatchVideo) > Number(lesson)
    return true
  }
  return true
}

const validateTimeForLesson = setting => {
  if (setting && setting.hasRangeTime) {
    const timeZone = dayjs().valueOf()
    const startTime = dayjs(`${setting.rangeTimeFromDate}T${setting.rangeTimeFromHour}:00.000+07:00`).valueOf()
    const endTime = dayjs(`${setting.rangeTimeToDate}T${setting.rangeTimeToHour}:00.000+07:00`).valueOf()
    return timeZone > startTime && timeZone < endTime
  }
  return true
}

const isValidateMarketSite = (vendorId) => {
  const listApprovedDomain = [
    '23b0bfb0-c00b-11e9-a060-17d3861b7a5b',
    '12bb4084-65ac-45ef-81af-ffd230a4ca64',
    '3175a1c0-8a9e-11ea-abfd-09ae7174bd87'
  ]
  return listApprovedDomain.includes(vendorId)
}

export default {
  isImage,
  isAndroid,
  isSafari,
  isIphone,
  validateTimesWatchVideo,
  validateTimeForLesson,
  isValidateMarketSite
}
