const POPUP = {
  TITLE: 'Lớp học trực tuyến',
  CTA_BTN: {
    JOIN: 'Tham gia',
    IS_FINISHED: 'Đã kết thúc',
  },
  EMPTY_DESCRIPTION: 'Chưa có phòng học Zoom theo lịch',
}

export default {
  POPUP,
}