import max from 'lodash-es/max'
import min from 'lodash-es/min'
const mapQuestionsGroup = (questions) => {
  const listTypeGroup = [
    'group',
    'single-choice-child',
    'multiple-choice-child',
    'fill-blank-child',
    'true-false-choice-child',
  ]
  let rawQuestions = questions.filter((qe) => listTypeGroup.indexOf(qe.type) < 0)
  let listGroups = questions.filter((q) => q.type === 'group')
  listGroups = listGroups.map((group) => {
    if (!group.children || (group.children.length === 0) || !group.children[0]?.id) {
      group.children = group.questions
        .map((item) => {
          let questionId = typeof item === 'string' ? item : item.id
          const question = questions.find((qe) => qe.id === questionId)
          return {
            ...question,
            parentId: group.id,
          }
        })
        .filter((item) => item.id)
    }
    return group
  })
  rawQuestions = rawQuestions.concat(listGroups)
  return rawQuestions.sort((a, b) => a.index - b.index)
}

const reindexQuestions = (questions) => {
  const sortedQuestions = [...questions].sort((a, b) => a.index - b.index)
  // gộp nhóm
  let stackQuestions = []
  sortedQuestions.forEach((question) => {
    if (question.type === 'group') {
      stackQuestions.push({
        ...question,
        children: question.questions.map((i) => sortedQuestions.find((q) => q.id === i)),
      })
    } else {
      if (!question.type.includes('child')) stackQuestions.push(question)
    }
  })
  // đánh lại index
  let newIndex = 0
  stackQuestions = stackQuestions.map((question) => {
    if (question.type === 'group') {
      return {
        ...question,
        index: newIndex,
        children: question.children.map((child) => {
          const updatedChild = {...child, index: newIndex}
          newIndex++
          return updatedChild
        }),
      }
    } else {
      const updatedQuestion = {...question, index: newIndex}
      newIndex++
      return updatedQuestion
    }
  })
  // trải phẳng ra
  // Flatten stackQuestions after indexing
  const flattenedQuestions = stackQuestions.reduce((acc, question) => {
    if (question.type === 'group') {
      // Add the group question itself
      acc.push(question)
      // Add all child questions
      acc.push(...question.children)
    } else {
      acc.push(question)
    }
    return acc
  }, [])

  // Sort the flattened questions by index
  flattenedQuestions.sort((a, b) => a.index - b.index)
  console.log('flattenedQuestions', flattenedQuestions)
  return flattenedQuestions
}

const setIndexRandomQuestions = (allQuestions) => {
  let questions = allQuestions.filter((q) => !q.type.includes('child'))
  let randomQuestions = []
  let currentIndex = 0
  questions.forEach((q) => {
    if (q.type === 'group') {
      randomQuestions.push({...q, index: currentIndex})
      q.questions.forEach((child) => {
        const childQuestion = allQuestions.find((i) => i.id === child.toString())
        randomQuestions.push({...childQuestion, index: currentIndex})
        currentIndex++
      })
    } else {
      randomQuestions.push({...q, index: currentIndex})
      currentIndex++
    }
  })
  return randomQuestions
}

const transformQuestionPart = (questionsInPart, questions) => {
  let currentIndex = 0
  let newQuestions = questionsInPart.map((q) => {
    // Kiểm tra nếu q là chuỗi
    const questionObj = typeof q === 'string' ? questions.find((item) => item.id === q) : q

    if (questionObj.type === 'group') {
      let newQuestion = {...questionObj, index: currentIndex}
      let child = questionObj.children.map((child) => {
        // Kiểm tra nếu child là chuỗi
        const childObj = typeof child === 'string' ? questions.find((item) => item.id === child) : child
        let newChild = {...childObj, index: currentIndex}
        currentIndex++
        return newChild
      })
      newQuestion = {...newQuestion, children: child}
      return newQuestion
    } else {
      let newQuestion = {...questionObj, index: currentIndex}
      currentIndex++
      return newQuestion
    }
  })
  return newQuestions
}

const getFromToQuestionGroup = (question) => {
  if (question.children && question.children.length > 1) {
    const listIndex = question.children.map((child) => Number(child.index))
    return `${min(listIndex) + 1}-${max(listIndex) + 1}`
  }
  return 'đơn'
}

export default {
  reindexQuestions,
  setIndexRandomQuestions,
  mapQuestionsGroup,
  getFromToQuestionGroup,
  transformQuestionPart,
}
