var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { id: "app" } },
    [
      _c("router-view"),
      _c("Seo"),
      _c("Zoom"),
      _c("BaseMessage"),
      _c("BasePopup"),
      _c("BaseAuthenticationPopup"),
      _c("BaseContactPopup"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }