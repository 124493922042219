import get from 'lodash-es/get'
import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const UnitRepository = RepositoryFactory.get('unit')
const defaultState = () =>  {
  return {
    units: {},
    unit: {},
    count: 0,
    activeUnit: null,
    isOverViews: false,
    isSearching: false,
    unitsSearch: [],
    textSearch: ''
  }
}
const state = defaultState()

const actions = {
  async createUnit({ commit }, data) {
    const unit = await UnitRepository.create(data)
    commit('addUnit', unit.data)
    return unit.data
  },
  async fetchUnits({ commit }, params = {}) {
    commit('setSearching', !!params.tags_contain)
    const res = await UnitRepository.fetch({
      ...params,
    })
    commit('setUnits', res.data || [])
    return res.data
  },
  async searchUnit({ commit }, params = {}) {
    commit('setSearching', !!params.tags_contain)
    const res = await UnitRepository.fetch({
      ...params,
    })
    commit('setUnitsSearch', res.data || [])
    return res.data
  },
  async fetchUnit({ rootState, state, commit }, id) {
    try {
      const res = await UnitRepository.fetchOne(id + `?_populate=video&userId=${rootState.auth.auth.id}`)
      commit('setUnitData', res.data || {})
      commit('setIsOverViews', false)
      return res.data
    } catch (error) {
      if (error.response.data.code == 501) {
        commit('setIsOverViews', true)
        commit('setUnitData', state.units[id])
      }
      return error.response.data
    }
  },
  async countUnits({ commit }, params = {}) {
    const res = await UnitRepository.count({
      ...params,
      type: 'unit',
    })
    commit('setCount', res.data.Count || 0)
  },
  async updateUnit({ commit }, { id, ...unit }) {
    const res = await UnitRepository.update(id, unit)
    return commit('setUnit', res.data)
  },
  async updateUnitOrder(units) {
    for (let i = 0; i < units.length; i++) {
      await UnitRepository.update(units[i].id, { index: i })
    }
  },
  async removeUnit({ commit }, item) {
    await UnitRepository.remove(item.id, {
      vendorId: item.vendorId,
    })
    return commit('removeUnit', item.id)
  },
  async setUnit({ commit }, unit) {
    return commit('setUnitData', unit)
  },
  async addUnit({ commit }, unit) {
    return commit('setUnit', unit)
  },
  setActiveUnit({ commit }, unit) {
    return commit('setActiveUnit', unit)
  },
}

const mutations = {
  setSearching(state, isSearching) {
    return (state.isSearching = isSearching)
  },
  setUnit(state, unit) {
    return (state.units = {
      ...state.units,
      [unit.id]: unit,
    })
  },
  setUnitsSearch(state, units) {
    return state.unitsSearch = units
  },
  setCount(state, count) {
    return (state.count = count)
  },
  addUnit(state, unit) {
    state.count = state.count + 1
    state.units = {
      [unit.id]: unit,
      ...state.units,
    }
  },
  updateUnits(state, units) {
    state.units = {
      ...units.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
      ...state.units,
    }
  },
  setUnitData(state, unit) {
    state.unit = unit
    return state.unit
  },
  appendUnits(state, units) {
    return (state.units = {
      ...units.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
      ...state.units,
    })
  },
  setUnits(state, units) {
    return (state.units = units.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}))
  },
  removeUnit(state, id) {
    delete state.units[id]
    state.units = { ...state.units }
  },
  setActiveUnit(state, activeUnit) {
    state.activeUnit = activeUnit
  },
  setIsOverViews(state, value) {
    state.isOverViews = value
  },
  setTextSearch(state, value) {
    state.textSearch = value
  },
  setCompleteForUnitSearch(state, unitId) {
    state.unitsSearch = state.unitsSearch.map((u) => {
      if (u.id === unitId) {
        console.log('set complete', u.id, unitId);
        u.isDone = true
      }
      return u
    })
  },
  reset(state) {
    Object.assign(state, defaultState())
  }
}

const getters = {
  units: (state) => {
    return Object.values(state.units).sort((a, b) => {
      const aIndex = a.index ? a.index : 0
      const bIndex = b.index ? b.index : 0
      return aIndex - bIndex
    })
  },
  unitsSearch: (state) => {
    return state.unitsSearch.sort((a, b) => {
      const aIndex = a.index ? a.index : 0
      const bIndex = b.index ? b.index : 0
      return aIndex - bIndex
    })
  },
  objectUnits: (state) => {
    return state.units
  },
  getUnitsBySectionId: (state) => (sectionId) => {
    return Object.values(state.units)
      .filter((a) => a.section === sectionId)
      .sort((a, b) => {
        const aIndex = a.index ? a.index : 0
        const bIndex = b.index ? b.index : 0
        return aIndex - bIndex
      })
  },
  count: (state) => {
    return state.count
  },
  getUnit: (state) => {
    return state.unit
  },
  isUnitValid: (state) => {
    const unitId = get(state, 'unit.id')
    return typeof unitId === 'string' && unitId.length > 0
  },
  activeUnit: (state) => {
    return state.activeUnit
  },
  getIsOverViews: (state) => {
    return state.isOverViews
  },
  getIsSearching: (state) => {
    return state.isSearching
  },
  getTextSearch: (state) => {
    return state.textSearch
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
