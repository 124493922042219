export default {
	MSG_ACTIVATE_SUCCESS: 'Activate course successfully!',
	MSG_CODE_PLACEHOLDER: 'Please enter active code!',
	MSG_IS_OWNED_COURSE: 'You already own this course!',
	MSG_NOT_EXIST: 'Active code not exist!',
	MSG_IS_EXPIRED: 'Active code has expired!',
	MSG_CODE_CANNOT_BE_USED: 'Active code is not allowed to use!',
	MSG_INVALID_ACCOUNT: 'Invalid Account !',
	MSG_ACTIVATE_LOADING: 'Activating course...',

	BTN_ACTIVATE: "Activate now",
  TITLE: 'Activate course',
  HINT: "Attention: Each course can only be activated once",
  INPUT_PLACEHOLDER: "Enter active code, e.g: 1233AV415",
}