import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const ExamRepository = RepositoryFactory.get('exam')
const UtilityRepository = RepositoryFactory.get('utility')
import get from 'lodash-es/get'
import utils from '@/helpers/utils.js'

const state = {
  exams: {},
  exam: {},
  count: 0,
  isStatusReport: true,
  timezone: 0
};

const actions = {
  resetExam({ state }) {
    state.exams = {};
    state.exam = {};
    state.count = 0;
  },
  async fetchExamById({ commit }, id) {
    const res = await ExamRepository.fetchOne(id);
    commit("setExamData", res.data);
    return res.data
  },
  async fetchTimeServer({ commit }) {
    const res = await UtilityRepository.getTimeServer();
    commit("setTimezone", res.data);
    return res.data
  },
  async fetchExams({ commit }, params = {}) {
    const res = await ExamRepository.fetch({
      ...params,
    });
    commit("setExams", res.data || []);
    return res.data;
  },
  changeReportStatus({ commit }, isReport = true) {
    commit("setReportView", isReport)
  },
  setExam({ commit }, exam) {
    commit("setExamData", exam);
  }
};

const mutations = {
  setExams(state, exams) {
    return state.exams = exams;
  },
  setExamData(state, exam) {
    return (state.exam = exam);
  },
  setReportView(state, isReport) {
    return state.isStatusReport = isReport
  },
  setTimezone(state, timezone) {
    return state.timezone = timezone
  }
};

const getters = {
  exams: (state) => {
    return state.exams;
  },
  getExam: (state) => {
    return state.exam
  },
  getReportStatus: (state) => {
    return state.isStatusReport
  },
  settings: (state) => {
    return get(state.exam, 'settings', null)
  },
  isPublicResult: (state) => {
    const settings = get(state.exam, 'settings', null)
    if (settings) {
      const endTime = utils.dayjs(`${settings.publicDate}T${settings.publicHour}:00.000+07:00`).valueOf()
      return !settings.hasPublicationTime || (settings.hasPublicationTime && endTime < new Date().valueOf())
    }
    return false
  },
  timeServer: (state) => {
    return state.timezone
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
