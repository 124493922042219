import get from 'lodash-es/get'
import last from 'lodash-es/last'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

// lesson

const getRangeTimeForUnit = (settings = {}) => {
  if (settings.hasRangeTime) {
    return {
      startAfter: dayjs(`${settings.rangeTimeFromDate}T${settings.rangeTimeFromHour}:00.000+07:00`).toISOString(),
      finishBefore: dayjs(`${settings.rangeTimeToDate}T${settings.rangeTimeToHour}:00.000+07:00`).toISOString()
    }
  }
  return { startAfter: null, finishBefore: null }
}

const getAttachmentsForUnit = (unit = {}) => {
  if (get(unit, 'data.hasAttachment', false)) {
    const syllabus = get(unit, 'syllabus', {})
    return { attachments: [{ ...syllabus, name: syllabus.title, size: syllabus.size }] }
  }
  return { attachments: [] }
}

const getNextLesson = (indeces = {}, unitId = '', objectUnits = {}) => {
  const keys = Object.keys(indeces)
  const current = Number(keys.find(key => indeces[key] === unitId))
  if (current < keys.length - 1) return objectUnits[indeces[current + 1]]
  return null
}

const getPrevLesson = (sections, sectionIndex, lessonIndex) => {
  let previousLesson = {}
  if (lessonIndex === 0) {
    if (sectionIndex !== 0) {
      const previousSectionLessons = sections[sectionIndex - 1].lessons
      previousLesson = last(previousSectionLessons)
    }
  } else {
    previousLesson = sections[sectionIndex].lessons[lessonIndex - 1]
  }
  return previousLesson
}

// certificate

const getDataToCreateCertificate = (authUser, student, certificate) => {
  return {
    userId: authUser.id,
    certificate: certificate.id,
    student: student.id,
    course: get(certificate, 'course.id', ''),
    expired: certificate.expired,
    dataType: certificate.dataType,
    title: certificate.title,
    subTitle: certificate.subTitle,
    description: certificate.description,
    subDescription: certificate.subDescription,
    expiredTitle: certificate.expiredTitle,
    certificationName: certificate.certificationName
  }
}

const getDataView = ({ duration, time, isDone, type, greaterMinScore }, currentUnit) => {
  return {
    updatedAt: new Date().toISOString(),
    resourceId: get(currentUnit, 'resources[0].id', ''),
    isDone,
    duration,
    unitId: currentUnit.id,
    sectionId: currentUnit.section,
    time,
    type,
    greaterMinScore: !!greaterMinScore
  }
}
/**
 * Init syllabus heights, use for multi-level section in syllabus
 * @param {Array} syllabus 
 */
const initSyllabusHeights = (syllabus = []) => {
  const listData = []
  syllabus.forEach(section => {
    let data = { height: -1 }
    if (Array.isArray(section.child) && section.child.length) {
      data.children = []
      for (let i = 0; i < section.child.length; i++) {
        data.children.push({ height: -1 })
      }
    }
    listData.push(data)
  })
  return listData
}

export default {
  getRangeTimeForUnit,
  getAttachmentsForUnit,
  getDataView,
  getDataToCreateCertificate,
  getNextLesson,
  getPrevLesson,
  initSyllabusHeights,
}
