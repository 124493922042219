import authentication from 'iztech-design-system-neo/dist/system/i18n/jp/authentication'

const CONFIRM_SIGN_UP = {
	MSG_INVALID_INFO: '認証情報が無効です。',
	MSG_SIGN_UP_SUCCESS: 'アカウントが有効化されました。',
	MSG_USER_NOT_FOUND: 'ユーザー情報が見つかりません。もう一度お試し、またはサポートにお問い合わせください。',
	MSG_SIGNUP_FAIL: '登録の確認に失敗しました。もう一度お試し、またはサポートにお問い合わせください。',
	MSG_INVALID_TOKEN: 'ログインに失敗しました。トークンをもう一度ご確認ください!',
}

export default {
	...authentication,
	CONFIRM_SIGN_UP,
	MSG_WRONG_CREDENTIALS: 'アカウントまたはパスワードが無効です !',

	MSG_REQUEST_CHANGE_PASSWORD_SUCCESS: 'パスワードが正常に変更されました。',
	MSG_REQUEST_CHANGE_PASSWORD_FAIL: 'パスワード変更要求が失敗しました !',
	
	MSG_REQUEST_FORGOT_PASSWORD_SUCCESS: '確認コードが送信されました。',
	MSG_REQUEST_FORGOT_PASSWORD_FAIL: 'パスワードを忘れた場合の要求に失敗しました',
	
	MSG_CONFIRM_FORGOT_PASSWORD_SUCCESS: 'パスワードが正常に変更されました。再ログインしてください!',
	MSG_CONFIRM_FORGOT_PASSWORD_FAIL: 'パスワードの確認に失敗しました',

	MSG_SIGNIN_SUCCESS: 'ログインに成功しました!',
	MSG_SIGNIN_NOT_VERIFIED: 'アカウントがアクティブ化されていません !',
	MSG_SIGNIN_NOT_EXIST: 'アカウントが存在しません !',

	MSG_SIGNUP_SUCCESS: '登録に成功しました。メールにアクセスして有効にしてください',
	MSG_SIGNUP_FAIL: '登録に失敗しました !',
	MSG_SIGNUP_EXIST_EMAIL: 'メールは既に存在します !',
	MSG_SIGNUP_DISABLE: '認証管理者に連絡してアカウントを登録してください !',

	MSG_ACCOUNT_SIGNED_IN_OTHER_DEVICE: 'ご使用のアカウントは、既に別の端末で利用されています。',
  MSG_SIGN_IN_VIA_LINK_DESCRIPTION: 'コースへのアクセス',
}