import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const Combo = RepositoryFactory.get('combo')

const state = {
  combos: [],
  combo: {},
  validItemsInCombo: [],
}

const actions = {
  async fetchCombos({commit}, params = {}) {
    const res = await Combo.fetch({...params})
    commit('setCombos', res.data || [])
    return res.data || []
  },
  async fetchCombo({commit}, id) {
    const res = await Combo.fetchOne(id)
    commit('setCombo', res.data || {})
    return res.data || {}
  },
  setValidItemsInCombo({commit}, items = []) {
    commit('setValidItemsInCombo', items)
  },
}

const mutations = {
  setCombos(state, data) {
    state.combos = [...data]
  },
  setCombo(state, data) {
    state.combo = {...data}
  },
  setValidItemsInCombo(state, items = []) {
    state.validItemsInCombo = items
  },
}

const getters = {
  getCombos: (state) => state.combos,
  getCombo: (state) => state.combo,
  getValidItemsInCombo: (state) => state.validItemsInCombo,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
