import Vue from 'vue'
import { sync } from 'vuex-router-sync'
import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import global from '@/global/index.js'
import helpers from '@/helpers/index.js'
import pluginHelper from '@/helpers/pluginHelper.js'
import utils from '@/helpers/utils.js'
import clipboard from 'v-clipboard'
import vuetify from '@/plugins/vuetify'
import message from '@/plugins/message'
import notify from '@/plugins/notify'
import popup from '@/plugins/popup'
import triggerPopup from '@/plugins/triggerPopup.js'
import cartRepository from '@/api/repository/cartRepository.js'
import authPopup from '@/plugins/authPopup'
import contactPopup from '@/plugins/contactPopup'
import inputRules from '@/plugins/inputRules'
import renderUIFromJSON from '@/plugins/renderUIFromJSON'
import DesignSystem from 'iztech-design-system-neo'
import 'iztech-design-system-neo/dist/system/system.css'
import '@/assets/scss/main.scss'
import cart from '@/store/modules/cart.js'
import vendor from '@/store/modules/vendor.js'
import get from 'lodash-es/get'
import interceptorsSetup from '@/api/core/interceptors.js'
import chatMessenger from '@/plugins/chatMessengerPlugin.js'
import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const UserCourseRepository = RepositoryFactory.get('userCourse')

// Add the Firebase products that you want to use
global.import()
Vue.config.productionTip = false
Vue.config.performance = true
Vue.config.devtools = true

sync(store, router, { moduleName: '$route' })

Vue.use(renderUIFromJSON, {
  router,
  store,
})

Vue.use(
  pluginHelper.create({
    $clipboard: clipboard,
    $utils: utils,
    $helpers: helpers,
    $message: message,
    $notify: notify,
    $popup: popup,
    $authPopup: authPopup,
    $rules: inputRules,
    $contactPopup: contactPopup
  })
)
Vue.use(DesignSystem, {
  environment: process.env.NODE_ENV,
  functions: {
    redirect: (link) => {
      if (typeof link === 'string' && link.length) {
        if (!link.startsWith('/')) router.push(`/${link}`)
        else router.push(link)
      } else router.push(link)
    },
    addToCart: (item) => {
      if (item.type === 'membership') {
        addItemsToLocalstorage(item)
        this.$message.success(this.$vuetify.lang.t('$vuetify.MESSAGES.ADD_TO_CART_SUCCESS'))
      } else if (item.type === 'combo') {
        router.push(`/combo/${item.id}`)
      } else {
        if (item.isFree || item.disablePurchasing) {
          router.push(`/course-online-detail/${item.id}`)
        } else {
          addItemsToLocalstorage({ ...item, type: item.type === 'online' ? 'online-course' : item.type })
          this.$message.success(this.$vuetify.lang.t('$vuetify.MESSAGES.ADD_TO_CART_SUCCESS'))
        }
      }
    },
    submitForm: async (data) => {
      try {
        await cartRepository.createSubmitForm(data)
        triggerPopup.emit('show-success-popup', '$vuetify.MESSAGES.FORM_SUBMIT_SUCCESS')
      } catch (err) {
        triggerPopup.emit('show-error-popup', '$vuetify.MESSAGES.FORM_SUBMIT_ERROR')
      }
    },
    downloadApp: (link) => {
      window.open(link, '_blank', 'noopener noreferrer')
    },
    redirectToLearningPage: async (course) => {
      await UserCourseRepository.enroll({ courseId: course.id })
      let isDoneUnit = get(course, 'userCourse.dataDetails.views', [])
      isDoneUnit.sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt))
      let lastLearningUnit = isDoneUnit ? isDoneUnit[0] : null
      const sectionId = lastLearningUnit ? lastLearningUnit.sectionId : null
      const unitId = lastLearningUnit ? lastLearningUnit.unitId : null
      router.push(`/course-online-learning/${course.id}/${sectionId}/${unitId}`)
    },
    addMembershipToCart: (item) => {
      if (item.type === 'membership') {
        if (item.disablePurchasing) {
          return
        }
        const isHeaderMarket = get(vendor, 'state.settings.learn.isDisplayMarketHeader')
        if (!isHeaderMarket) {
          removeItemsFromLocalstorage()
          addItemsToLocalstorage(item, false)
          router.push(`/checkoutCart`)
        } else {
          addItemsToLocalstorage(item)
        }
      }
    },
  },
})

function removeItemsFromLocalstorage() {
  window.localStorage.removeItem('cart-items')
  cart.mutations.setCart(cart.state, [])
}

function addItemsToLocalstorage(item, isShowPopup = true) {
  try {
    let newCartitems = cart.state.cartItems ? cart.state.cartItems : []
    let listItemIds = newCartitems.map((item) => item.id)
    if (listItemIds.indexOf(item.id) > -1) {
      throw new Error('$vuetify.MESSAGES.PRODUCT_EXIST_IN_CART')
    } else {
      newCartitems.push(item)
      const stringtifyItems = JSON.stringify(newCartitems)
      window.localStorage.setItem('cart-items', stringtifyItems)
      cart.mutations.setCart(cart.state, newCartitems)
    }
    if (isShowPopup) {
      triggerPopup.emit('show-success-popup', '$vuetify.MESSAGES.ADD_TO_CART_SUCCESS')
    }
  } catch (error) {
    triggerPopup.emit('show-error-popup', error)
  }
}

interceptorsSetup()
chatMessenger()

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
