/**
 * this served as header, footer for site
 * - what specific for header/footer will be separated
 * - what not will be shown in export
 */

const HEADER = {
  CATEGORY: "カテゴリ",
  RECRUITMENT: "講師募集·",
  ACTIVATE: "アクティベート",
}

const FOOTER = {
  ACTIVATE_COURSE: "コースをアクティブ化",
}

export default {
  HEADER,
  FOOTER,
  DEPARTMENT: "学舎",
  BOOK: "書類",
  ABOUT_US: "私たちについて",
  PRIVACY_POLICY: "プライバシーポリシー",
  BLOG: "ブログ",
}