import checkout from 'iztech-design-system-neo/dist/system/i18n/vi/checkout'

const CART_SECTION = {
  ...checkout.CART_SECTION,
  BILL: {
    ...checkout.CART_SECTION.BILL,
    MSG_VALID_COUPON_CODE: 'Mã kích hoạt hợp lệ!',
  },
}

export default {
  ...checkout,
  CART_SECTION,
  MSG_CONTACT_CREATED_SUCCESS: 'Chúng tôi đã nhận được thông tin của bạn. Nếu bạn muốn sở hữu khoá học, vui lòng đăng ký tài khoản.',
}