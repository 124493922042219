<template>
	<Seo />
</template>
<script>
import Seo from '@/modules/Seo/index.vue'
export default {
	components: {
		Seo,
	},
}
</script>
