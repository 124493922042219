const AUTH_USER_STORAGE_NAME = 'ol-credentials-auth-user'
const VENDOR_STORAGE_NAME = 'ol-amplify-vendorId-from-origin'
import {decipher} from '@/helpers/cipher.js'

function _loadAuthUser() {
  let authUserEncoded = localStorage.getItem(AUTH_USER_STORAGE_NAME)
  let vendorInfo = JSON.parse(localStorage.getItem(VENDOR_STORAGE_NAME))
  try {
    return authUserEncoded ? JSON.parse(decipher(vendorInfo.payload.vendorId)(authUserEncoded)) : null
  } catch (err) {
    return null
  }
}

const AuthFilter = async (to, from, next) => {
  const authUser = _loadAuthUser()
  if (
    authUser &&
    (authUser.roles.includes('admin') || authUser.roles.includes('teacher') || authUser.roles.includes('student'))
  ) {
    next()
  } else {
    next('/')
  }
}
export default AuthFilter
