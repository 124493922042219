/* eslint-disable */
import vuetify from '@/plugins/vuetify'
import get from 'lodash-es/get'
import specificUtils from '@/helpers/specificUtils/index.js'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import dayjsVi from 'dayjs/locale/vi'
import dayjsEn from 'dayjs/locale/en'
import dayjsJp from 'dayjs/locale/ja'
import unidecode from 'unidecode'

const getLocale = (currentLang = 'vi') => {
  switch (currentLang) {
    case 'vi':
      return dayjsVi
    case 'en':
      return dayjsEn
    case 'jp':
      return dayjsJp
    default:
      return dayjsVi
  }
}

dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)

const onDownloadFileWithUrl = (link = '', fromS3 = true) => {
  if (typeof link === 'string' && link.length) {
    // normlize to NFD (Composite Unicode handler),
    // latin characters will be untouched
    let url = link.normalize('NFD')
    // ensure link from s3 has been properly wrapped with aws-cdn
    if (fromS3) {
      if (!link.includes('http')) url = combineLinkWithBucket(url)
      url = convertURL(url)
    }
    // ensure URL is encoded to avoid unicode characters in URL,
    // which may caused URL broken and cannot be downloaded
    url = encodeURI(url)
    window.open(url, '_blank', 'noopener noreferrer')
  }
}
const onDownloadSyllabus = (syllabus) => {
  onDownloadFileWithUrl(get(syllabus, 'origin.link', ''))
}

const clearUnicode = (alias = '', hyphen = true) => {
  var str = alias
  str = str.toLowerCase()
  str = unidecode(str)
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ')
  str = str.replace(/ + /g, ' ')
  if (hyphen) str = str.trim().split(' ').join('-')
  return str.trim()
}

const combineLinkWithBucket = (linkResource) => {
  if (linkResource.includes('http')) return linkResource
  return process.env.VUE_APP_AWS_BUCKET_CLOUD_FRONT + '/' + linkResource
}

const wrapperLinkImage = (image = {}) => {
  let originLink = convertURL(combineLinkWithBucket(image.origin.link))
  return {
    ...image,
    link: image.link ? convertURL(combineLinkWithBucket(image.link)) : originLink,
    origin: {
      ...image.origin,
      link: originLink,
    },
  }
}

const bytesToSize = (bytes) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes == 0) return '0 Byte'
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
}

const truncate = (input) => {
  if (input.length > 5) {
    return input.substring(0, 16) + '...'
  }
  return input
}

const convertURL = (url, withoutCloudfront) => {
  if (!url) return url
  let convertedUrl = url
  if (!url.includes('https') && url.includes('http')) {
    let urlWithHttps = url.replace('http', 'https')
    convertedUrl = urlWithHttps
  }
  const pattern = /https:\/\/online-learning-izteach-\d-aws-source-bucket\.s3-ap-southeast-1\.amazonaws\.com/
  const replacement = process.env.VUE_APP_AWS_BUCKET_CLOUD_FRONT
  return withoutCloudfront ? convertedUrl : convertedUrl.replace(pattern, replacement)
}

const getLinkWithoutCache = (link) => {
  const convertedLink = convertURL(link)
  return `${convertedLink}?time=${Date.now()}&origin=${get(window, 'location.host', '')}`
}

const parseScore = (score, number) => {
  return Number(score.toFixed(number))
}

const exceedTimesWatchVideo = (authuser, currentLesson, conditionWatchVideo) => {
  if (conditionWatchVideo && conditionWatchVideo.isCountExceedWatchVideo) {
    const authUserId = authuser.id
    let data = JSON.parse(window.localStorage.getItem('exceed-times-watch-video'))
    let payload = data || {}
    let lesson = get(payload, `${authUserId}.${currentLesson.id}`, null)
    if (lesson) payload[authUserId][currentLesson.id]++
    else payload[authUserId] = {...payload[authUserId], [currentLesson.id]: 1}
    localStorage.setItem('exceed-times-watch-video', JSON.stringify(payload))
  }
}

const getInitialLearnerCount = (object) => {
  const isFakeLearner = get(object, 'data.isFakedLearner', false)
  if (isFakeLearner) {
    const initialStudents = get(object, 'data.totalLearnedStudents', 0)
    const incrementStudents = get(object, 'data.incrementStudents', 0)
    if (incrementStudents > 0) {
      const dateDiff = new Date() - new Date(get(object, 'data.incrementStartDate'))
      return initialStudents + incrementStudents * (parseInt(dateDiff / (1000 * 3600 * 24)) + 1)
    }
    return initialStudents
  }
  return 0
}

const delay = (ms) => new Promise((res) => setTimeout(res, ms))

const canShowDiscussion = (currentCourse = {}, currentUnit = {}) => {
  const isDiscussionAllowedInCourse = get(currentCourse, 'data.canEnableDiscussion', false)
  if (isDiscussionAllowedInCourse) {
    const isDiscussionAllowedInUnit = get(currentUnit, 'data.canEnableDiscussion')
    if (typeof isDiscussionAllowedInUnit === 'boolean') return isDiscussionAllowedInUnit
    return true
  }
  return false
}

const parseFileSize = (size = 0) => {
  if (isNaN(size) || Number.isNaN(size)) return ''
  const fileSizeTypes = ['B', 'KB', 'MB', 'GB', 'TB']
  let result = size,
    count = 0
  while (result >= 1024) {
    result /= 1024
    ++count
  }
  return `${result.toFixed(2)} ${fileSizeTypes[count]}`
}

const convertDateToDateTimeVietnamese = (date, hasSecondInTime = true) => {
  const displayedDate = dayjs(date).format('DD/MM/YYYY')
  const displayedTime = dayjs(date).format(hasSecondInTime ? 'HH:mm:ss' : 'HH:mm')
  return `${displayedDate}, lúc ${displayedTime}`
}

const convertRangeTime = (time) => {
  const diff = Date.parse(new Date(time)) - Date.now()
  if (diff < 0) return false

  const seconds = Math.floor(diff / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const remainingHours = hours % 24

  let result = ''
  if (days > 0) {
    result += `${days} ngày `
  }
  if (remainingHours > 0) {
    result += `${remainingHours} tiếng`
  }

  return result.trim() || '0 ngày'
}
const checkGreaterMinScore = (score, settings) => {
  if (settings.hasMinScore && settings.minScore) {
    return score >= Number(settings.minScore)
  }
}

const getLimitTime = (limitTime) => {
  if (!limitTime) return null
  const isTimeOut = new Date().getTime() > new Date(limitTime).getTime()
  const parseTime = isTimeOut ? 'timeout' : limitTime ? convertRangeTime(limitTime) : null
  return parseTime
}

const checkNavigatorMobile = () => {
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}

const checkDoubleKnock = () => {
  let ex =
    /(coc_coc_browser)|(Chrome\/95.0.4638.84)|(Chrome\/96.0.4664.116)|(Chrome\/96.0.4664.124)|(Chrome\/97.0.4692.104)|(Chrome\/98.0.4758.116)|(Chrome\/99.0.4844.88)|(Chrome\/100.0.4896.136)|(Chrome\/101.0.4951.80)|(Chrome\/102.0.5005.132)|(Chrome\/103.0.5060.134)|(Chrome\/104.0.5112.84)|(Chrome\/105.0.0.0)|(Chrome\/106.0.0.0)/
  let agent = navigator.userAgent
  return !checkNavigatorMobile() && (navigator.vendor === 'Coc Coc' || ex.test(agent))
}

const formatTitle = (title, charlimit, hasSpacing = true) => {
  let titleFormat = ''
  let titleLimit = charlimit
  if (title && title.length) {
    if (title.length > titleLimit) {
      titleFormat = title.slice(0, titleLimit) + '...'
    } else {
      if (hasSpacing) {
        title = title + (' ' + String.fromCharCode(160)).repeat(Math.floor(titleLimit - title.length))
      }
      titleFormat = title
    }
  }
  return titleFormat
}

const countRemainingDays = (totalDay, submitTime) => {
  const submitDate = new Date(submitTime).getTime()
  const curDate = new Date().getTime()
  const diffDays = Math.floor((curDate - submitDate) / 86400000)
  const result = totalDay - diffDays
  return result > 0 ? result : 0
}
const convertAttachmentToArray = (syllabus) => {
  const resources = syllabus ? [syllabus] : []
  return Array.isArray(syllabus) ? syllabus : resources
}
export default {
  combineLinkWithBucket,
  convertURL,
  onDownloadFileWithUrl,
  onDownloadSyllabus,
  getLinkWithoutCache,
  wrapperLinkImage,
  exceedTimesWatchVideo,
  parseScore,
  getInitialLearnerCount,
  delay,
  canShowDiscussion,
  parseFileSize,
  dayjs,
  truncate,
  bytesToSize,
  clearUnicode,
  convertDateToDateTimeVietnamese,
  getLocale,
  checkGreaterMinScore,
  convertRangeTime,
  getLimitTime,
  checkDoubleKnock,
  formatTitle,
  countRemainingDays,
  convertAttachmentToArray,
  ...specificUtils,
}
