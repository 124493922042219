/**
 * this served for legacy payment,
 * which works for ebook and course (when setting is not e-commercial site)
 */

const STEP_AUTHENTICATE = {
  TITLE: 'Xác thực',
  FORM_SIGN_IN_TITLE: 'Đăng nhập để giao dịch nhanh chóng hơn',
  FORM_APPLY_DISCOUNT_CODE_SIGN_IN: 'Bạn phải đăng nhập để sử dụng mã giảm giá!',
  BTN_NOT_APPLY_DISCOUNT: 'Không dùng',
  FORM_SKIP_SIGN_IN_TITLE: 'Thanh toán không cần đăng nhập',
}

const STEP_PAYMENT = {
  TITLE: 'Thanh toán',
  BTN_CHECKOUT: 'Thanh toán',
  METHOD: {
    TITLE: 'Phương thức thanh toán',
    DIRECT_PAY: {
      TITLE: 'Đăng ký thanh toán trực tiếp tại quầy',
    },
    BANK_TRANSFER: {
      TITLE: 'Chuyển khoản ngân hàng nội địa',
      NOTE_LABEL: 'Mẫu chuyển khoản',
      BANK_NAME_LABEL: 'Tên ngân hàng',
      ACCOUNT_NANE_LABEL: 'Tên tài khoản',
      ACCOUNT_NUMBER_LABEL: 'Số tài khoản',
    },
    GATEWAY_TRANSFER: {
      TITLE: 'Thanh toán qua {0}',
    },
  },
}

const STEP_CONFIRMATION = {
  TITLE: 'Xác nhận',
  BANK_TRANSFER: {
    TITLE: 'Hệ thống đã xác nhận thông tin, vui lòng chờ duyệt',
    DESCRIPTION: 'Thông tin chuyển khoản của bạn sẽ được xác nhận sớm nhất có thể. Mọi thắc mắc xin liên hệ hotline trợ giúp',
  },
  OTHER_METHOD: {
    TITLE: 'Đăng ký thành công',
    DESCRIPTION: 'Chúng tôi sẽ liên hệ với bạn sớm nhất có thể. Mọi thắc mắc xin liên hệ trợ giúp.',
    CONTACT_INFO_TITLE: 'Thông tin liên hệ',
  },
  PAYMENT_ERROR: {
    TITLE: 'Thanh toán thất bại',
    DESCRIPTION: 'Đã có lỗi xảy ra. Vui lòng thử lại hoặc liên hệ với trung tâm hỗ trợ khách hàng theo hotline',
  },
}

const INFO = {
  CHECKOUT_LABEL: 'Thanh toán:',
  ONLINE_COURSE: {
    DISCOUNT_CODE_PLACEHOLDER: 'Nhập mã giảm giá',
    MSG_DISCOUNT_CODE_INVALID: 'Mã giảm giá không hợp lệ',
    MSG_DISCOUNT_CODE_VALID: 'Xác nhận mã giảm giá thành công',
    DISCOUNT_LABEL: '(Được giảm: -{0})',
  },
  OFFLINE_COURSE: {
    PRICE_NONE: 'Chưa có giá',
    PRICE_PER_CLASS: '{0}/khoá',
  },
}

export default {
  STEP_AUTHENTICATE,
  STEP_PAYMENT,
  STEP_CONFIRMATION,
  INFO,
  MSG_CREATE_ORDER_FAIL: 'Tạo đơn thất bại',
  MSG_CREATE_ORDER_LOADING: 'Đang tạo đơn hàng',
  MSG_VALIDATE_ORDER: 'Đang kiểm tra đơn hàng',
  MSG_EXISTED_PRODUCT: 'Sản phẩm {0} đã tồn tại trong đơn hàng'
}