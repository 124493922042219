import checkout from 'iztech-design-system-neo/dist/system/i18n/en/checkout'

const CART_SECTION = {
  ...checkout.CART_SECTION,
  BILL: {
    ...checkout.CART_SECTION.BILL,
    MSG_VALID_COUPON_CODE: 'Valid Coupon Code!',
  },
}

export default {
  ...checkout,
  CART_SECTION,
  MSG_CONTACT_CREATED_SUCCESS: 'We have received your order. If you want to own course immediately, please sign up an account.',
}