import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const Membership = RepositoryFactory.get('membership')

const state = {
  memberships: [],
  membership: {},
  userMembership: [],
}

const actions = {
  async fetchMemberships({commit}, params = {}) {
    const res = await Membership.fetch({...params, status: 'published'})
    commit('setMemberships', res.data || [])
    return res.data || []
  },
  async fetchMembershipsByUserId({commit}, id) {
    const res = await Membership.fetchMembershipsByUserId(id)
    commit('setUserMemberships', res.data || [])
    return res.data || []
  },
  async fetchMembership({commit}, id) {
    const res = await Membership.fetchOne(id)
    commit('setMembership', res.data || {})
    return res.data || {}
  },
  removeMembership({commit}) {
    commit('removeMembership')
  },
}

const mutations = {
  setMemberships(state, data) {
    state.memberships = [...data]
  },
  setUserMemberships(state, data) {
    state.userMembership = [...data]
  },
  setMembership(state, data) {
    state.membership = {...data}
  },
  removeMembership(state) {
    state.membership = {}
  },
}

const getters = {
  memberships: (state) => state.memberships,
  getMembership: (state) => state.membership,
  getUserMembership: (state) => state.userMembership,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
