import learning from 'iztech-design-system-neo/dist/system/i18n/vi/learning'

const EXERCISE = {
  ...learning.EXERCISE,
  SUBMIT_ACTION: {
    ...learning.EXERCISE.SUBMIT_ACTION,
    BTN_VIEW_CORRECTION: 'Xem nhận xét & bài chữa',
    DENIED_LABEL: 'Đã bị từ chối',
    BTN_VIEW_DENY_REASON: 'Xem lý do',
    BTN_RE_SUBMIT_VIA_QR: 'Nộp lại bài qua QR Code',
    BTN_SUBMIT_VIA_QR: 'Nộp bài qua QR Code',
  },
  DESCRIPTION: {
    TITLE: 'Nội dung bài',
    EMPTY_HINT: 'Bài tập này chưa có mô tả',
  },
  QR_POPUP: {
    TITLE: 'Vui lòng sử dụng camera để quét mã QR phía dưới',
    QR_EXPIRATION_LABEL: 'Mã hết hạn sau:',
    BTN_RE_CREATE_QR: 'Tạo lại mã QR',
  },
  UPLOAD_POPUP: {
    DATA_SUBMIT_WITH_LINK: 'Bài tập nộp bằng đường dẫn',
    MSG_REQUIRED_LINK: 'Vui lòng kiểm tra lại đường dẫn',
    MSG_EXCEED_SUBMIT_TIMES: 'Chỉ được nộp {0} lần',
    MSG_INVALID_SUBMIT_MOMENT: 'Thời gian không hợp lệ',
    MSG_EXPIRED_QR_CODE: 'Mã QR đã hết hạn, vui lòng tạo lại mã khác !',
    MSG_SUBMIT_SUCCESS: 'Nộp bài tập thành công',
    MSG_EXCEED_FILE_LIMIT: 'Chỉ được tải lên {0} tệp và không vượt quá {1}',
  },
  RESULT: {
    ...learning.EXERCISE.RESULT,
    MSG_EMPTY_CORRECTION: 'Hiện không thể mở do lỗi dữ liệu',
  },
  QR_UPLOAD: {
    TITLE: 'Nộp bài tập tại đây',
    DESCRIPTION: 'Thời gian từ lúc tạo mã QR để nộp bài là {0} phút',
    MSG_INVALID_PERMISSION: 'Không có quyền truy cập',
  },
}

const EMPTY_UNIT_MESSAGE = {
  TITLE: 'Bài giảng đang được thiết lập',
  BODY: 'Bài giảng bạn đang xem hiện đang được thiết lập. Vui lòng chuyển sang bài giảng khác.'
}

const NON_EXIST_UNIT_MESSAGE = {
  TITLE: 'Bài giảng không tồn tại',
  BODY: 'Bài giảng bạn đang xem hiện không tồn tại. Hãy tải lại trang hoặc chuyển sang bài giảng khác.'
}

const VIDEO = {
  ...learning.VIDEO,
  MSG_IS_CONVERTING: 'Video đang được xử lý, vui lòng thử lại sau!',
  // legacy player - play through s3
  MSG_S3_ERROR_TITLE: 'Không tải được video',
  MSG_S3_ERROR_DESCRIPTION: 'Vui lòng liên hệ đội ngũ hỗ trợ kỹ thuật.',
  MSG_S3_INVALID_LINK: 'Không tìm thấy đường dẫn video!',
  MSG_DOUBLE_KNOCK: 'Bản quyền nội dung số đối với video không được hỗ trợ tại Cốc Cốc, video có thể bị tải xuống một cách vô tình hoặc cố ý. Chúng tôi khuyến nghị bạn sử dụng trình duyệt khác để có được trải nghiệm học tập tốt nhất. Bạn có thể tham khảo các trình duyệt như Chrome, Firefox, Safari...',
}

const EXAMINATION = {
  ...learning.EXAMINATION,
  RESULT: {
    ...learning.EXAMINATION.RESULT,
    QUESTION_FILL_BLANK_ANSWER_NOT_FOUND: 'chưa trả lời',
  },
  POPUP_AFTER_SUBMIT: {
    TITLE: 'Chúc mừng',
    DESCRIPTION: 'Bạn đã hoàn thành bài kiểm tra',
    DISPLAYED_MARK_LABEL: 'Với số điểm của bạn là'
  },
  EXPIRE_TIME_CALCULATED_BY_QUESTION: "Theo từng câu",
  GROUP_QUESTION_LABEL: "Nhóm câu hỏi",
  MSG_LOST_FOCUS: "Bạn đã mất tập trung {0} lần !",
  BTN_NEXT_PARTITION: "Phần sau",
  BTN_PREV_PARTITION: "Phần trước",
  MSG_CONFIRM_NEXT_PARTITION: 'Nếu chuyển sang phần tiếp theo bạn sẽ không thể trở lại và sửa đáp án của phần này nữa. Bạn có chắc chắn muốn thực hiện thao tác này ?',
  SUBMIT_ACTION: {
    ...learning.EXAMINATION.SUBMIT_ACTION,
    ONGOING_LABEL_PARTITION: "phần",
  },
  MSG_OBO_CONFIRM_SUBMIT: 'Bạn có chắc chắn muốn nộp bài hay không?',
}

const COURSE_LESSON = {
  ...learning.COURSE_LESSON,
  BTN_REFRESH: "Tải lại trang"
}

const CERTIFICATE = {
  ...learning.CERTIFICATE,
  MSG_NO_CERT_FOR_COURSE: 'Khoá học không cung cấp chứng chỉ!',
  MSG_CREATE_LOADING: 'Đang tạo chứng chỉ...',
  MSG_CREATE_FAIL: 'Tạo chứng chỉ thất bại!',
  RELATED_COURSES_TITLE: 'Có thể bạn quan tâm',
}

const COMMON_RULES = {
  ...learning.COMMON_RULES,
  RESULT_PUBLISHED_TIME_LABEL: 'Thời gian nhận kết quả',
}

export default {
  ...learning,
  EMPTY_UNIT_MESSAGE,
  NON_EXIST_UNIT_MESSAGE,
  EXAMINATION,
  EXERCISE,
  VIDEO,
  COURSE_LESSON,
  CERTIFICATE,
  COMMON_RULES,
  COMPLETED: 'hoàn thành',
  DESCRIPTION: 'Mô tả',
  BTN_DOWNLOAD: 'Tải xuống',
  BTN_NEXT_LESSON: 'Bài tiếp theo',
  SUBMIT_CONFIRMATION_ANSWER: 'Bạn đã chắc chắn muốn nộp bài ?',
  MSG_SUBMIT_WARNING: 'Bạn phải nộp bài kiểm tra trước khi chuyển sang bài khác!',
  TIME: 'Thời gian',
  VIEW_RESULT: 'Xem kết quả',
  EXACT_MINUTES_AND_SECONDS: '{0} phút {1} giây',
  NOT_IN_ALLOWANCE_TIME_AND_TRY_AGAIN: 'Không trong khoảng thời gian truy cập, vui lòng quay lại sau!',
  EXIT_COURSE_WARNING: 'Bạn có thật sự chắc chắn muốn thoát khỏi khoá học này?',
  POPUP_CONFIRM_CONTINUE: 'Xác nhận chuyển câu',
  POPUP_CONFIRM_SUBMIT: 'Xác nhận nộp bài',
  MSG_NO_ACCESS_COURSE: 'Bạn không có quyền truy cập khoá học này',
  MSG_NO_NEXT_LESSON: 'Không có bài giảng tiếp theo',
	MSG_CANNOT_MOVE_TO_NEXT_LESSON: 'Bạn đã học tới bài cuối cùng. Hiện không còn bài giảng nào nữa.',
}
