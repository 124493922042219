import checkout from 'iztech-design-system-neo/dist/system/i18n/jp/checkout'

const CART_SECTION = {
  ...checkout.CART_SECTION,
  BILL: {
    ...checkout.CART_SECTION.BILL,
    MSG_VALID_COUPON_CODE: 'アクティベーションコードが有効です。',
  },
}

export default {
  ...checkout,
  CART_SECTION,
  MSG_CONTACT_CREATED_SUCCESS: 'ご注文を承りました。すぐにコースを所有したい場合は、アカウントにサインアップしてください。',
}