var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "400", persistent: _vm.authPopup.isPersistent },
      model: {
        value: _vm.authPopup.isShow,
        callback: function ($$v) {
          _vm.$set(_vm.authPopup, "isShow", $$v)
        },
        expression: "authPopup.isShow",
      },
    },
    [
      _c(
        "div",
        { staticStyle: { "background-color": "#ffffff" } },
        [
          _c("Authencation", {
            attrs: {
              state: _vm.authPopup.state,
              isLoading: _vm.authPopup.isLoading,
            },
            on: {
              "change-state": (state) => _vm.$authPopup.show(state),
              onRequestSignUp: (data) => _vm.onRequestSignUp(data),
              onRequestSignIn: (data) => _vm.onRequestSignIn(data),
              onRequestForgotPassword: (data) =>
                _vm.onRequestForgotPassword(data),
              onConfirmForgotPassword: (data) =>
                _vm.onConfirmForgotPassword(data),
              onRequestChangePassword: (data) =>
                _vm.onRequestChangePassword(data),
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }