<template>
	<div class="text-center z-index" v-if="dialog">
		<v-dialog v-model="dialog" width="500" scrollable>
			<v-card v-if="!loading">
				<v-card-title class="title" primary-title>
					{{$vuetify.lang.t('$vuetify.ZOOM.POPUP.TITLE')}}
					<span class="ml-2 red--circle"></span>
					<v-spacer />
					<v-icon @click="dialog = false">mdi-close</v-icon>
				</v-card-title>
				<v-card-text v-if="zooms.length > 0" style="min-height: 300px;">
					<div
						class="item d-flex justify-space-between pa-3 my-3"
						v-for="(item, index) in zooms"
						:key="index"
					>
						<div>
							<div class="subtitle-1 font-weight-medium">{{ item.title }}</div>
							<div class="mt-2">
								<v-icon small class="mr-1">mdi-account-supervisor</v-icon>
								{{ item.teacher }}
							</div>
							<div class="mt-2">
								<v-icon small class="mr-2">mdi-clock-outline</v-icon>
								{{ item.startTime | beautiDateAndTime }} - {{ item.endTime | beautiDateAndTime}}
							</div>
						</div>
            <div class="d-flex align-center">
              <v-btn
                color="primary"
                depressed
                :disabled="isRoomAvailable(item.endTime) ? false : true"
                :href="item.link"
                target="_blank"
              >
                {{ generateCTAButtonTitle(item.endTime) }}
              </v-btn>
            </div>
					</div>
				</v-card-text>
				<v-card-text v-else>
					<p class="font-weight-medium subtitle-1">
						{{$vuetify.lang.t('$vuetify.ZOOM.POPUP.EMPTY_DESCRIPTION')}}
					</p>
				</v-card-text>
			</v-card>
			<v-card v-else>
				<base-loading fluid color="primary" style="height: 500px" />
			</v-card>
		</v-dialog>
	</div>
	<p v-else></p>
</template>

<script>
import dayjs from 'dayjs'
export default {
	props: {state: Boolean, zooms: Array, loading: Boolean},
	watch: {
		async state() {
			this.dialog = true
		},
	},
	data() {
		return {
			dialog: false,
		}
	},
	filters: {
		beautiDateAndTime(date) {
			return date ? dayjs(date).format('HH:mm, DD/MM/YYYY') : ''
		}
	},
	methods: {
		generateCTAButtonTitle(end) {
			if (this.isRoomAvailable(end)) {
				return this.$vuetify.lang.t('$vuetify.ZOOM.POPUP.CTA_BTN.JOIN')
			} else {
				return this.$vuetify.lang.t('$vuetify.ZOOM.POPUP.CTA_BTN.IS_FINISHED')
			}
		},
		isRoomAvailable(end) {
			let endDate = end ? this.$utils.dayjs(end) : null
			return this.$utils.dayjs().diff(endDate) < 0
		}
	},
}
</script>
<style lang="scss" scoped>
.fixed {
	position: fixed;
}
.red--circle {
	border-radius: 50%;
	background-color: red;
	width: 8px;
	height: 8px;
}
.item {
	background: #f8f8f8;
	border: 1px solid #e6e4eb;
	box-sizing: border-box;
	border-radius: 4px;
}
.z-index {
	z-index: 9999;
	position: fixed;
}
</style>
