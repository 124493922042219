const POPUP = {
  TITLE: "Xin chúc mừng!",
  SUBTITLE: "Bạn đã hoàn thành chương trình học có thể nhận chứng chỉ ngay",
}

export default {
  POPUP,
  TITLE: "Hoàn thành khóa học",
  SUBTITLE: "Bạn có thể xem lại chứng chỉ này ở trong mục Khóa học của tôi",
  BTN_DOWNLOAD_CERTIFICATE: "Tải chứng chỉ (.PDF)",
  BTN_RECEIVE_CERTIFICATE: "Nhận chứng chỉ",
}