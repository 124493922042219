import dayjs from 'dayjs'
import get from 'lodash-es/get'
import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const Attendance = RepositoryFactory.get('offlineClassAttendance')

const state = {}

const actions = {
  async fetchAttendanceStudentReport(_, params = {}) {
    const res = await Attendance.fetchStudentReportAttendance(params)
    return get(res, 'data', []).sort((a, b) => dayjs(a.date) - dayjs(b.date))
  },
}

const mutations = {}

const getters = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
