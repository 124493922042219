import get from 'lodash-es/get'

const _getRealRatingFromItem = (item, index) => {
  return Math.max(
    get(item, `data.realTotalRating_${index}`, 0),
    get(item, `data.totalRating_${index}`, 0)
  )
}

const _getFakeRatingFromItem = (item, index) => {
  return get(item, `data.totalRating_${index}`, 0)
}

const getTotalRating = (item, isRatingFake = false) => {
  let result = 0
  if (isRatingFake) {
    for (let i = 1; i < 6; i++) result += _getFakeRatingFromItem(item, i)
  } else {
    for (let i = 1; i < 6; i++) result += _getRealRatingFromItem(item, i)
  }
  return result
}

const getAverageRating = (item, totalRating = 0, isRatingFake = false) => {
  let totalMultiple = 0
  if (isRatingFake) {
    for (let i = 1; i < 6; i++)
      totalMultiple += (_getRealRatingFromItem(item, i) + _getFakeRatingFromItem(item, i)) * i
  } else {
    for (let i = 1; i < 6; i++) totalMultiple += _getRealRatingFromItem(item, i) * i
  }
  if (totalRating > 0) {
    const temp = totalMultiple / totalRating
    return temp % 1 === 0 ? temp : +temp.toFixed(2)
  }
  return 0
}

const mutateDataWithRating = (data) => {
  const isRatingFake = get(data, 'data.isRatingFake', false)
  let ratingCount = 0, averageRating = 0
  if (isRatingFake) {
    ratingCount = getTotalRating(data) + getTotalRating(data, true)
    averageRating = getAverageRating(data, ratingCount, true)
  } else {
    ratingCount = getTotalRating(data)
    averageRating = getAverageRating(data, ratingCount)
  }
  return {
    ...data,
    ratingCount,
    averageRating,
  }
}

const calculateTotalReactions = item => {
  let result = 0
  if (!item.data) return 0
  Object.keys(item.data).forEach(key => {
    if (key.startsWith('totalReaction')) {
      result += item.data[String(key)]
    }
  })
  return result
}

export default {
  getTotalRating,
  getAverageRating,
  mutateDataWithRating,
  calculateTotalReactions,
}