var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialog
    ? _c(
        "div",
        { staticClass: "text-center z-index" },
        [
          _c(
            "v-dialog",
            {
              attrs: { width: "500", scrollable: "" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              !_vm.loading
                ? _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "title",
                          attrs: { "primary-title": "" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t("$vuetify.ZOOM.POPUP.TITLE")
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "ml-2 red--circle" }),
                          _c("v-spacer"),
                          _c(
                            "v-icon",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.dialog = false
                                },
                              },
                            },
                            [_vm._v("mdi-close")]
                          ),
                        ],
                        1
                      ),
                      _vm.zooms.length > 0
                        ? _c(
                            "v-card-text",
                            { staticStyle: { "min-height": "300px" } },
                            _vm._l(_vm.zooms, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "item d-flex justify-space-between pa-3 my-3",
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "subtitle-1 font-weight-medium",
                                      },
                                      [_vm._v(_vm._s(item.title))]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "mt-2" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-1",
                                            attrs: { small: "" },
                                          },
                                          [_vm._v("mdi-account-supervisor")]
                                        ),
                                        _vm._v(
                                          " " + _vm._s(item.teacher) + " "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "mt-2" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-2",
                                            attrs: { small: "" },
                                          },
                                          [_vm._v("mdi-clock-outline")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("beautiDateAndTime")(
                                                item.startTime
                                              )
                                            ) +
                                            " - " +
                                            _vm._s(
                                              _vm._f("beautiDateAndTime")(
                                                item.endTime
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            depressed: "",
                                            disabled: _vm.isRoomAvailable(
                                              item.endTime
                                            )
                                              ? false
                                              : true,
                                            href: item.link,
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.generateCTAButtonTitle(
                                                  item.endTime
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _c("v-card-text", [
                            _c(
                              "p",
                              { staticClass: "font-weight-medium subtitle-1" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.ZOOM.POPUP.EMPTY_DESCRIPTION"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                    ],
                    1
                  )
                : _c(
                    "v-card",
                    [
                      _c("base-loading", {
                        staticStyle: { height: "500px" },
                        attrs: { fluid: "", color: "primary" },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      )
    : _c("p")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }