/**
 * this served as header, footer for site
 * - what specific for header/footer will be separated
 * - what not will be shown in export
 */

const HEADER = {
  CATEGORY: "Danh mục",
  RECRUITMENT: "Recruitment",
  ACTIVATE: "Activate",
}

const FOOTER = {
  ACTIVATE_COURSE: "Activate Course",
}

export default {
  HEADER,
  FOOTER,
  DEPARTMENT: "Department",
  BOOK: "Books",
  ABOUT_US: "About Us",
  PRIVACY_POLICY: "Privacy Policy",
  BLOG: "Blog",
}