import authentication from 'iztech-design-system-neo/dist/system/i18n/en/authentication'

const CONFIRM_SIGN_UP = {
	MSG_INVALID_INFO: 'Invalid information!',
	MSG_SIGN_UP_SUCCESS: 'Your account has been activated successfully !',
	MSG_USER_NOT_FOUND: 'Not found user information. Please try again or contact support!',
	MSG_SIGNUP_FAIL: 'Sign up fail. Please try again or contact support!',
	MSG_INVALID_TOKEN: 'Sign in fail. Please re-check token!',
}

export default {
	...authentication,
	CONFIRM_SIGN_UP,
	MSG_WRONG_CREDENTIALS: 'Account or password is incorrect !',

	MSG_REQUEST_CHANGE_PASSWORD_SUCCESS: 'Changed password successfully!',
	MSG_REQUEST_CHANGE_PASSWORD_FAIL: 'Request change password fail !',

	MSG_REQUEST_FORGOT_PASSWORD_SUCCESS: 'Confirmation code sent!',
	MSG_REQUEST_FORGOT_PASSWORD_FAIL: 'Request forgot password fail',
	
	MSG_CONFIRM_FORGOT_PASSWORD_SUCCESS: 'Changed password successfully, please sign in again!',
	MSG_CONFIRM_FORGOT_PASSWORD_FAIL: 'Confirm forgot password fail',

	MSG_SIGNIN_SUCCESS: 'Signed in successfully!',
	MSG_SIGNIN_NOT_VERIFIED: 'User has not been verified yet !',
	MSG_SIGNIN_NOT_EXIST: 'User is not existed !',

	MSG_SIGNUP_SUCCESS: 'Sign up successful, please access your email to activate your account!',
	MSG_SIGNUP_FAIL: 'Sign up fail !',
	MSG_SIGNUP_EXIST_EMAIL: 'Email existed !',
	MSG_SIGNUP_DISABLE: 'Please contact Admin to register your account !',

	MSG_ACCOUNT_SIGNED_IN_OTHER_DEVICE: 'Your account has been signed in on other device! Please sign in again!',
  MSG_SIGN_IN_VIA_LINK_DESCRIPTION: 'Acessing course',
}