export default {
	MSG_ACTIVATE_SUCCESS: 'Kích hoạt khóa học thành công!',
	MSG_IS_OWNED_COURSE: 'Bạn đã sở hữu khóa học!',
	MSG_NOT_EXIST: 'Mã kích hoạt không tồn tại hoặc đã được sử dụng!',
	MSG_IS_EXPIRED: 'Mã kích hoạt đã quá hạn sử dụng!',
	MSG_CODE_CANNOT_BE_USED: 'Mã kích hoạt chưa được phép sử dụng!',
	MSG_INVALID_ACCOUNT: 'Tài khoản không hợp lệ !',
	MSG_ACTIVATE_LOADING: 'Đang kích hoạt khoá học...',
	MSG_CODE_PLACEHOLDER: 'Vui lòng nhập code khóa học!',

  BTN_ACTIVATE: "Kích hoạt ngay",
  TITLE: 'Kích hoạt khoá học',
  HINT: "Lưu ý: mỗi khóa học chỉ cần kích hoạt một lần duy nhất",
  INPUT_PLACEHOLDER: "Nhập mã kích hoạt, ví dụ: 1233AV415",
}