/**
 * this served as header, footer for site
 * - what specific for header/footer will be separated
 * - what not will be shown in export
 */

const HEADER = {
  CATEGORY: "Danh mục",
  RECRUITMENT: "Trở thành giảng viên",
  ACTIVATE: "Kích hoạt",
}

const FOOTER = {
  ACTIVATE_COURSE: "Kích hoạt khóa học",
}

export default {
  HEADER,
  FOOTER,
  DEPARTMENT: "Cơ sở",
  BOOK: "Tài liệu",
  ABOUT_US: "Về chúng tôi",
  PRIVACY_POLICY: "Chính sách bảo mật",
  BLOG: "Blog",
}