import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const NotificationRepository = RepositoryFactory.get('notification')

const state = {
  notifications: [],
  notification: {},
  count: 0,
  baitNotifications: [],
  unread: 0
}

const actions = {
  async fetchNotifications({ rootState, commit }, params = {}) {
    const res = await NotificationRepository.fetch({ ...params, _sort: 'createdAt:-1' })
    const rootDomain = rootState.vendor.vendor.rootDomain
    const mapping = res.data.map(item => {
      const isLocal = rootDomain === 'localhost'
      const protocol = isLocal ? 'http' : 'https'
      const domain = `${protocol}://${isLocal ? 'localhost:4000' : rootDomain}`
      return { ...item, to: item['href'] ? `${domain}${item['href']}` : '', content: item.html }
    })
    commit('setNotifications', mapping)
    return res.data
  },
  async fetchNotificationById({ commit }, id) {
    const res = await NotificationRepository.fetchOne(id)
    commit('setNotification', res.data)
    return res.data
  },
  async countNotification({ commit }, params = {}) {
    const res = await NotificationRepository.count(params)
    commit('setCount', res.data.count)
    commit('setUnread', res.data.unread)
    return res.data
  },
  async updateNotification({ commit }, { id, params }) {
    const res = await NotificationRepository.update(id, params)
    commit('setNotification', res.data)
    return res.data
  },
  async markAllRead({ commit, state }, { id, params }) {
    const res = await NotificationRepository.markReadAll(id, params)
    if (res.data.length) {
      commit('setNotifications', state.notifications.map(item => { return { ...item, read: true } }))
      commit('setUnread', 0)
    }
    return res.data
  },
  async markRead({ rootState, commit, state }, item) {
    if (item.read) return
    const res = await NotificationRepository.markRead(item.id, {})
    const newNotis = state.notifications.map(item => {
      if (item.id === res.data.id) {
        const rootDomain = rootState.vendor.vendor.rootDomain
        const isLocal = rootDomain === 'localhost'
        const protocol = isLocal ? 'http' : 'https'
        const domain = `${protocol}://${isLocal ? 'localhost:4000' : rootDomain}`
        return { ...res.data, updatedAt: item.updatedAt, to: item['href'] ? `${domain}${item['href']}` : '', content: item.html }
      } else return item
    })
    if (res.data) commit('setNotifications', newNotis)
    return res.data
  }
}

const mutations = {
  setNotifications(state, notifications) {
    state.notifications = notifications
  },
  setUnread(state, count) {
    state.unread = count
  },
  setNotification(state, notification) {
    state.notifications = notification
  },
  setCount(state, count) {
    state.count = count
  },
  setBaitNotifications(state, baitNotifications) {
    state.baitNotifications = baitNotifications
  }
}

const getters = {
  getNotifications: state => {
    return state.notifications.map(noti => {
      return {
        ...noti,
        avatar: 'https://freeiconshop.com/wp-content/uploads/edd/apple-flat-1.png'
      }
    })
  },
  getCountNoti: state => {
    return state.count
  },
  getUnreadNoti: state => {
    return state.unread
  },
  getBaitNotifications: state => {
    return state.baitNotifications.map(noti => {
      return {
        ...noti,
        avatar: 'https://freeiconshop.com/wp-content/uploads/edd/apple-flat-1.png'
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
