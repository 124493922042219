<template>
	<div>Đây là ô nhập</div>
</template>
<script>
export default {
	data() {
		return {}
	},
	created() {},
}
</script>
