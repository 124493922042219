import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const RatingRepository = RepositoryFactory.get('rating')
const state = {
  ratings: [],
  count: 0,
  userRate: {},

}
const actions = {
  async fetchRatings({ commit }, params) {
    const res = await RatingRepository.fetch({ ...params, status: 'publish' })
    commit('setRatings', res.data)
    return res.data
  },
  async createRating({ commit, rootState }, params) {
    if (!rootState.auth.authenticated || !params.objectId) return
    const res = await RatingRepository.create({
      ...params,
      userId: rootState.auth.auth.id,
      userEmail: rootState.auth.auth.email,
      userName: rootState.auth.auth.name,
      userAvatarLink: rootState.auth.auth.avatar,
    })
    if (params.status === 'publish') commit('addOrUpdateRate', res.data || {})
    return res.data
  },
  async updateRating({ commit, rootState }, { id, ...rate }) {
    if (!rootState.auth.authenticated) return
    const res = await RatingRepository.update(id, {
      ...rate,
      userId: rootState.auth.auth.id,
      userEmail: rootState.auth.auth.email,
      userName: rootState.auth.auth.name,
      userAvatarLink: rootState.auth.auth.avatar,
    })
    if (res) {
      commit('addOrUpdateRate', res.data || {})
    }
    if (rate.status === 'publish') commit('addOrUpdateRate', res.data || {})
    else commit('removeRating', res.data || {})
    commit("setUserRate", res.data || {})
    return res.data
  },
  async fetchCount({ commit }, params) {
    const res = await RatingRepository.count({ ...params, status: 'publish' })
    commit("setCount", res.data || 0)
  },
  async fetchCurrentUserRate({ commit, rootState }, params) {
    if (!rootState.auth.authenticated) return
    const userId = rootState.auth.auth.id
    const res = await RatingRepository.fetch({ ...params, userId })
    commit("setUserRate", res.data ? res.data[0] : {})
  },
}
const mutations = {
  setRatings(state, payload) {
    state.ratings = payload
  },
  addOrUpdateRate(state, item) {
    if (item && item.id) {
      let foundIndex = state.ratings.findIndex(listItem => listItem && listItem.id === item.id)
      if (foundIndex >= 0) {
        state.ratings.splice(foundIndex, 1, item)
      } else {
        state.ratings.unshift(item)
      }
    }
  },
  setCount(state, payload) {
    state.count = payload
  },
  setUserRate(state, payload) {
    state.userRate = payload
  },
  resetRatings(state) {
    state.ratings = []
  },
  removeRating(state, item) {
    if (item && item.id) {
      let foundIndex = state.ratings.findIndex(listItem => listItem && listItem.id === item.id)
      if (foundIndex >= 0) {
        state.ratings = state.ratings.filter(listItem => listItem && listItem.id !== item.id)
      }
    }
  },
}
const getters = {
  getRatings: (state) => state.ratings,
  getCount: (state) => state.count,
  getUserRate: (state) => state.userRate
}
export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
