<template>
	<v-snackbar
		v-model="message.isDisplay"
		:bottom="y === 'bottom'"
		:color="message.color"
		:elevation="0"
		:left="x === 'left'"
		:multi-line="mode === 'multi-line'"
		:right="x === 'right'"
		:timeout="message.time || 3000"
		:top="y === 'top'"
		:vertical="mode === 'vertical'"
	>
		<div class="subtitle-1">
			<v-progress-circular
				v-if="message.color === 'grey darken-2'"
				class="mr-3"
				:width="3"
				:size="30"
				indeterminate
				color="white"
			></v-progress-circular>
			{{ translateMessage(message.text) }}
		</div>
		<template v-slot:action="{attrs}">
			<v-btn elevation="0" class="mr-2" dark icon v-bind="attrs" @click="close">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</template>
	</v-snackbar>
</template>
<script>
export default {
	props: {
		mode: String,
		x: String,
		y: {
			type: String,
			default: 'top',
		},
	},
	data() {
		return {
			message: this.$message.messageData,
		}
	},
	methods: {
		translateMessage(message) {
			if (typeof message === 'object') {
				if (typeof message.message === 'string' && message.message.indexOf('$vuetify') > -1) {
					return this.$vuetify.lang.t(message.message)
				}
				return message
			}
			if (message.indexOf('$vuetify') > -1) return this.$vuetify.lang.t(message)
			return message
		},
		close() {
			this.$message.close()
		},
	},
}
</script>