/* eslint-disable */
import axios from 'axios'
const VENDOR_BASE_URL = process.env.VUE_APP_API_VENDOR
const UTILITY_BASE_URL = process.env.VUE_APP_API_UTILITY
const ROOT_VENDOR_BASE_URL = process.env.VUE_APP_API_DOMAIN

const APIHelper = () => ({
	getVendorIdFromOrigin: async () => {
		let cache = _loadVendorIdCache()
		if (cache) {
			return cache
		}
		const payload = await axios.get(
			VENDOR_BASE_URL + `/vendor/get-vendor-from-origin?hostname=${location.origin}`
		)
		if (!payload.data.vendorId) {
			throw new Error(
				`[GetVendorIdFromOrigin] Invalid payload '${JSON.stringify(payload)}'!`
			)
		}
		_saveVendorIdCache(payload.data)
		return payload.data
	},
	getSettingsVendor: async vendor => {
		let vendorSettings = await axios.get(
			`${VENDOR_BASE_URL}/vendor-setting/${vendor.vendorId}`,
		)
		return { ...vendorSettings.data }
	},
	fetchDataForHomePage: async (vendorId, params) => {
		let { data: payload } = await axios.post(
			`${UTILITY_BASE_URL}/homepage/${vendorId}`,
			params
		)
		return payload
	},
	featchRootVender: async (domain) => {
		let { data: payload } = await axios.get(
			`${ROOT_VENDOR_BASE_URL}/vendor/${domain}`
		)
		return payload
	}
})

function _loadVendorIdCache() {
	let data = _jsonParse(localStorage.getItem('ol-amplify-vendorId-from-origin'))
	if (
		data &&
		data.origin == window.location.origin &&
		data.payload &&
		typeof data.payload.vendorId === 'string' &&
		Date.now() < data.expiredAt
	) {
		return data.payload
	}
	localStorage.removeItem('ol-amplify-vendorId-from-origin')
	return null
}

function _saveVendorIdCache(payload) {
	let data = {
		origin: window.location.origin,
		payload,
		expiredAt: Date.now() + 24 * 60 * 60 * 1000
	}
	localStorage.setItem('ol-amplify-vendorId-from-origin', JSON.stringify(data))
}

function _jsonParse(str) {
	try {
		return JSON.parse(str)
	} catch (err) {
		return null
	}
}

export const Vendor = APIHelper()
export default {
	Vendor
}
