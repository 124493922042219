import authentication from 'iztech-design-system-neo/dist/system/i18n/vi/authentication'

const CONFIRM_SIGN_UP = {
	MSG_INVALID_INFO: 'Thông tin xác thực không hợp lệ!',
	MSG_SIGN_UP_SUCCESS: 'Tài khoản của bạn đã được kích hoạt !',
	MSG_USER_NOT_FOUND: 'Không tìm thấy thông tin người dùng, vui lòng thử lại hoặc liên hệ trợ giúp!',
	MSG_SIGNUP_FAIL: 'Xác nhận đăng ký thất bại, vui lòng thử lại hoặc liên hệ trợ giúp!',
	MSG_INVALID_TOKEN: 'Đăng nhập không thành công. Vui lòng kiểm tra lại token!',
}

export default {
	...authentication,
	CONFIRM_SIGN_UP,
	MSG_WRONG_CREDENTIALS: 'Tài khoản hoặc mật khẩu không đúng !',

	MSG_REQUEST_CHANGE_PASSWORD_SUCCESS: 'Đổi mật khẩu thành công!',
	MSG_REQUEST_CHANGE_PASSWORD_FAIL: 'Yêu cầu đổi mật khẩu thất bại !',

	MSG_REQUEST_FORGOT_PASSWORD_SUCCESS: 'Đã gửi mã xác nhận!',
	MSG_REQUEST_FORGOT_PASSWORD_FAIL: 'Yêu cầu quên mật khẩu thất bại',

	MSG_CONFIRM_FORGOT_PASSWORD_SUCCESS: 'Đổi mật khẩu thành công, vui lòng đăng nhập lại!',
	MSG_CONFIRM_FORGOT_PASSWORD_FAIL: 'Yêu cầu xác nhận mật khẩu thất bại',

	MSG_SIGNIN_SUCCESS: 'Đăng nhập thành công!',
	MSG_SIGNIN_NOT_VERIFIED: 'Tài khoản chưa được kích hoạt !',
	MSG_SIGNIN_NOT_EXIST: 'Tài khoản không tồn tại !',

	MSG_SIGNUP_SUCCESS: 'Đăng kí thành công, truy cập email để kích hoạt!',
	MSG_SIGNUP_FAIL: 'Đăng ký thất bại !',
	MSG_SIGNUP_EXIST_EMAIL: 'Email đã tồn tại !',
	MSG_SIGNUP_DISABLE: 'Vui lòng liên hệ với Admin để đăng kí tài khoản !',
	
	MSG_ACCOUNT_SIGNED_IN_OTHER_DEVICE: 'Tài khoản đã được đăng nhập trên một thiết bị khác!',
  MSG_SIGN_IN_VIA_LINK_DESCRIPTION: 'Đang truy cập khóa học',
}