import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const evaluationCollectionRepository = RepositoryFactory.get('evaluationCollection')

const state = {
  evaluationCollections: [],
}

const actions = {
  async fetchCollections({commit, rootState}, params = {}) {
    params = {
      ...params,
      vendorId: rootState.vendor.vendorId,
    }
    const res = await evaluationCollectionRepository.fetch({...params})
    commit('setCollections', res.data || [])
    return res.data || []
  },
  resetCollections({commit}) {
    commit('resetCollections')
  },
}

const mutations = {
  setCollections(state, data) {
    state.evaluationCollections = data
  },
  resetCollections(state) {
    state.evaluationCollections = []
  },
}

const getters = {
  getCollections: (state) => state.evaluationCollections,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
