const POPUP = {
  TITLE: "おめでとうございます!",
  SUBTITLE: "マイコースでこの証明書を確認できます。",
}

export default {
  POPUP,
  TITLE: "コース完了",
  SUBTITLE: "学習プログラムを完了しました。すぐに証明書を取得できます。",
  BTN_DOWNLOAD_CERTIFICATE: "PDFダウンロード",
  BTN_RECEIVE_CERTIFICATE: "証明書取得",
}