import vendor from '@/store/modules/vendor'
import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_PAYMENT
const utilityURL = process.env.VUE_APP_API_UTILITY
import axios from 'axios'

const validateDiscount = (params) => {
  return Repository(baseURL + '/bill/validate').create(params)
}

const approveAfterPurchase = ({ data, options }) => {
  return axios.post(baseURL + '/bill/approve-after-purchase', { ...data }, options)
}

const createOrder = (params) => {
  return Repository(baseURL + '/bill').create({
    ...params,
    vendorId: vendor.state.vendor.vendorId,
  })
}

const updateOrder = (id, params) => {
  return Repository(baseURL + '/bill').update(id, params)
}

const createContact = (params) => {
  return Repository(utilityURL + '/order').create(params)
}

const updateContact = (id, params) => {
  return Repository(utilityURL + '/order').update(id, params)
}

const createSubmitForm = (params) => {
  return Repository(utilityURL + '/contact').create(params)
}

const validateAffiliate = (params) => {
  return Repository(baseURL + '/affiliate/validate').fetch(params)
}

const applyAffiliate = (id, params) => {
  return Repository(baseURL + '/affiliate/apply').update(id, params)
}

export default {
  validateDiscount,
  createOrder,
  updateOrder,
  createContact,
  updateContact,
  createSubmitForm,
  validateAffiliate,
  approveAfterPurchase,
  applyAffiliate,
}
