import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_EXAM + '/homework'

const fetch = params => {
  return Repository(baseURL).fetch(params)
}

const fetchOne = id => {
  return Repository(baseURL).fetchOne(id)
}

const create = params => {
  return Repository(baseURL).create(params)
}

const count = params => {
  return Repository(baseURL + '/count').fetch(params)
}

export default {
  fetch,
  fetchOne,
  create,
  count
}
