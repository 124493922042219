import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_OFFLINE_STACK + '/mark-student'

const fetch = (params) => {
  return Repository(baseURL).fetch(params)
}

const fetchOne = (id) => {
  return Repository(baseURL).fetchOne(id)
}

const create = (params) => {
  return Repository(baseURL).create(params)
}

const createMany = (params) => {
  return Repository(baseURL + '-many').create(params)
}

const update = (id, params) => {
  return Repository(baseURL).update(id, params)
}

const updateMany = (params) => {
  return Repository(baseURL + '-many').updateMany(params)
}

const count = (params) => {
  return Repository(baseURL + '-count').fetch(params)
}

const reportOneStudentMark = (params) => {
  return Repository(baseURL + '-report').fetch(params)
}

export default {
  fetch,
  fetchOne,
  create,
  update,
  count,
  createMany,
  updateMany,
  reportOneStudentMark,
}
