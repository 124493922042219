import { render, staticRenderFns } from "./BaseLoading.vue?vue&type=template&id=fef99690"
import script from "./BaseLoading.vue?vue&type=script&lang=js"
export * from "./BaseLoading.vue?vue&type=script&lang=js"
import style0 from "./BaseLoading.vue?vue&type=style&index=0&id=fef99690&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fef99690')) {
      api.createRecord('fef99690', component.options)
    } else {
      api.reload('fef99690', component.options)
    }
    module.hot.accept("./BaseLoading.vue?vue&type=template&id=fef99690", function () {
      api.rerender('fef99690', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/global/BaseLoading.vue"
export default component.exports