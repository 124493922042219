import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const CartRepository = RepositoryFactory.get('cart')
const namespaced = true
const LOCALSTORAGE_CART = 'cart-items'

const state = {
  cartItems: [],
  order: {},
  contact: {},
  comboDiscount: 0,
  couponDiscount: 0,
}

const actions = {
  fetchCart({ commit }) {
    const cartItems = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_CART))
    commit('setCart', cartItems)
  },
  setCart({ commit }, carts) {
    commit('setCart', carts)
  },
  async validateDiscount({ commit }, params) {
    const result = await CartRepository.validateDiscount(params)
    commit('setCombo', result.data.combo || 0)
    commit('setCoupon', result.data.coupon || 0)
    return result.data
  },
  async createOrder({ commit }, params) {
    const result = await CartRepository.createOrder(params)
    commit('setOrder', result.data)
    return result.data
  },
  async updateOrder({ commit }, { id, params }) {
    const result = await CartRepository.updateOrder(id, params)
    commit('setOrder', result.data)
    return result.data
  },
  async createContact({ commit }, params) {
    const result = await CartRepository.createContact(params)
    commit('setContact', result.data)
    return result.data
  },
  async updateContact({ commit }, { id, params }) {
    const result = await CartRepository.updateContact(id, params)
    commit('setContact', result.data)
    return result.data
  },
  async applyAffiliateForUser(_, { id, params }) {
    return await CartRepository.applyAffiliate(id, params)
  },
  addToCart({ commit, state }, newItem) {
    let newCartitems = state.cartItems ? state.cartItems : []
    let listItemIds = newCartitems.map((item) => item.id)
    if (listItemIds.indexOf(newItem.id) > -1) {
      throw new Error('$vuetify.MESSAGES.PRODUCT_EXIST_IN_CART')
    } else {
      newCartitems.push(newItem)
      const stringtifyItems = JSON.stringify(newCartitems)
      window.localStorage.setItem(LOCALSTORAGE_CART, stringtifyItems)
      commit('setCart', newCartitems)
    }
  },
  removeToCart({ commit, state }, itemId) {
    const filteredItems = state.cartItems.filter((item) => item.id !== itemId)
    const stringtifyItems = JSON.stringify(filteredItems)
    window.localStorage.setItem(LOCALSTORAGE_CART, stringtifyItems)
    commit('setCart', filteredItems)
  },
  clearCart({ commit }) {
    window.localStorage.removeItem(LOCALSTORAGE_CART)
    commit('setCart', [])
  },
  async validateAffiliate(_, params) {
    const result = await CartRepository.validateAffiliate(params)
    return result.data
  },
  async approveAfterPurchase(_, { data, options }) {
    return await CartRepository.approveAfterPurchase({ data, options })
  },
}

const mutations = {
  setCart(state, carts) {
    state.cartItems = carts
    return carts
  },
  setOrder(state, order) {
    state.order = order
  },
  setContact(state, contact) {
    state.contact = contact
  },
  setCoupon(state, coupon) {
    state.couponDiscount = coupon
  },
  setCombo(state, combo) {
    state.comboDiscount = combo
  },
}

const getters = {
  getItems: (state) => state.cartItems,
  getCartItemCount: (state) => (state.cartItems && state.cartItems ? state.cartItems.length : 0),
  getComboDiscount: (state) => state.comboDiscount,
  getCouponDiscount: (state) => state.couponDiscount,
}

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
}
