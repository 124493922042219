const POPUP = {
  TITLE: 'オンラインコース',
  CTA_BTN: {
    JOIN: '参加',
    IS_FINISHED: '終了',
  },
  EMPTY_DESCRIPTION: 'スケジュールとおりのZoomクラスが存在しません。',
}

export default {
  POPUP,
}
