const POPUP = {
  TITLE: "Congratulation!",
  SUBTITLE: "You have completed your study program, you can receive your certificate now",
}

export default {
  POPUP,
  TITLE: "Complete course",
  SUBTITLE: "You can review your certificate in 'My Course'",
  BTN_DOWNLOAD_CERTIFICATE: "Download certificate (.PDF)",
  BTN_RECEIVE_CERTIFICATE: "Receive Certificate",
}