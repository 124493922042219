<template>
	<Zoom />
</template>
<script>
import Zoom from '@/modules/Zoom'
export default {
	components: {
		Zoom,
	},
}
</script>