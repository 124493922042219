const ERROR = {
	SYSTEM: 'Lỗi hệ thống',
	UNEXPECTED: 'Đã có lỗi xảy ra. Vui lòng thử lại sau!',
  NETWORK: "Kết nối mạng không ổn định. Vui lòng kiểm tra lại kết nối mạng của bạn!",
}

const POPUP_DEFAULT_TITLE = {
	SUCCESS: 'Thông báo thành công',
	ERROR: 'Thông báo lỗi',
	WARNING: 'Cảnh báo',
	PROCEED: 'Bạn đã chắc chưa?',
}

export default {
	ERROR,
	POPUP_DEFAULT_TITLE,
	// cart
	ADD_TO_CART_SUCCESS: 'Thêm vào giỏ hàng thành công!',
	PRODUCT_EXIST_IN_CART: 'Sản phẩm này đã tồn tại trong giỏ hàng!',
	// loading
	UPDATING: 'Đang cập nhật...',
	LOADING: 'Đang tải...',
	// error when getting suitable data
	CANNOT_FIND_PDF_TRY_AGAIN: 'Không tìm thấy đường dẫn file pdf! Vui lòng thử lại.',
	CANNOT_FIND_MIME_TYPE: 'Không tìm thấy định dạng tệp phù hợp!',
	// section form submit
	FORM_SUBMIT_SUCCESS: 'Chúng tôi đã nhận được thông tin của bạn',
	FORM_SUBMIT_ERROR: 'Gửi thông tin thất bại. Vui lòng liên hệ với đội hỗ trợ',
}
