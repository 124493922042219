import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const UserBook = RepositoryFactory.get('userBook')

const state = {
  userBook: {},
  userBooks: []
}

const actions = {
  async fetchUserBook({rootState, commit}, bookId) {
    if (rootState.auth.authenticated) {
      let data = await UserBook.fetch({
        userId: rootState.auth.auth.id,
        book: bookId
      })
      commit('setUserBook', data.data && data.data.length ? data.data[0] : {})
      return data.data
    }
  },
  async fetchUserBooks({rootState, commit}) {
    if (rootState.auth.authenticated) {
      let result = await UserBook.fetch({
        userId: rootState.auth.auth.id
      })
      commit(
        'setUserBooks',
        result.data && result.data.length
          ? result.data
              .filter(userBook => userBook.book)
              .map(userBook => {
                return userBook.book
              })
          : []
      )
      return result.data
    }
  },
  async enrollUserBook({commit}, data) {
    const userBook = await UserBook.enroll(data)
    commit('setUserBook', userBook.data)
    return userBook.data
  },
  async interact({commit}, {id, history}) {
    const res = await UserBook.interact(id, history)
    commit('setUserBook', res.data)
    return res.data
  }
}

const mutations = {
  setUserBook(state, book) {
    return (state.userBook = book)
  },
  setUserBooks(state, books) {
    return (state.userBooks = books)
  }
}

const getters = {
  getUserBook: state => {
    return state.userBook
  },
  getUserBooks: state => {
    return state.userBooks
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
