/**
 * this served for legacy payment,
 * which works for ebook and course (when setting is not e-commercial site)
 */

const STEP_AUTHENTICATE = {
  TITLE: 'Authenticate',
  FORM_SIGN_IN_TITLE: 'Sign in to skip authentication',
  FORM_APPLY_DISCOUNT_CODE_SIGN_IN: 'You have to sign in to use discount code!',
  BTN_NOT_APPLY_DISCOUNT: "Don't use",
  FORM_SKIP_SIGN_IN_TITLE: 'Checkout without signing-in',
}

const STEP_PAYMENT = {
  TITLE: 'Payment',
  BTN_CHECKOUT: 'Checkout',
  METHOD: {
    TITLE: 'Payment Method',
    DIRECT_PAY: {
      TITLE: 'Direct pay',
    },
    BANK_TRANSFER: {
      TITLE: 'Domestic bank transfer',
      NOTE_LABEL: 'Note (template)',
      BANK_NAME_LABEL: 'Bank name',
      ACCOUNT_NANE_LABEL: 'Bank account name',
      ACCOUNT_NUMBER_LABEL: 'Bank account number',
    },
    GATEWAY_TRANSFER: {
      TITLE: 'Transfer through {0}',
    },
  },
}

const STEP_CONFIRMATION = {
  TITLE: 'Confirmation',
  BANK_TRANSFER: {
    TITLE: 'Your information has been confirmed, please wait while we approve them',
    DESCRIPTION: 'Your order will be approved as soon as possible. In case you need help, please contact us via hotline.',
  },
  OTHER_METHOD: {
    TITLE: 'Your order has been received',
    DESCRIPTION: 'We will contact you as soon as possible. In case you need help, please contact us via hotline.',
    CONTACT_INFO_TITLE: 'Contact information',
  },
  PAYMENT_ERROR: {
    TITLE: 'Fail checkout',
    DESCRIPTION: 'There is an error while checking out. Please try again later or contact with our customer service via hotline',
  },
}

const INFO = {
  CHECKOUT_LABEL: 'Checkout:',
  ONLINE_COURSE: {
    DISCOUNT_CODE_PLACEHOLDER: 'Enter discount code',
    MSG_DISCOUNT_CODE_INVALID: 'Invalid discount code',
    MSG_DISCOUNT_CODE_VALID: 'Discount code is valid',
    DISCOUNT_LABEL: '(Discount amount: -{0})',
  },
  OFFLINE_COURSE: {
    PRICE_NONE: 'No price',
    PRICE_PER_CLASS: '{0}/course',
  },
}

export default {
  STEP_AUTHENTICATE,
  STEP_PAYMENT,
  STEP_CONFIRMATION,
  INFO,
  MSG_CREATE_ORDER_FAIL: 'Fail to create order',
  MSG_CREATE_ORDER_LOADING: 'Creating order...',
  MSG_VALIDATE_ORDER: 'Validating order...',
  MSG_EXISTED_PRODUCT: 'Product {0} is already existed in cart'
}