import book from 'iztech-design-system-neo/dist/system/i18n/jp/book'

const SECTION_VIEW = {
  TITLE: 'ドキュメント： "{0}"',
  TITLE_SAME_TOPIC_LIST: "同様トピックの書類",
}

export default {
  ...book,
  SECTION_VIEW,
}