var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "400", persistent: _vm.contactPopup.isPersistent },
      model: {
        value: _vm.contactPopup.isShow,
        callback: function ($$v) {
          _vm.$set(_vm.contactPopup, "isShow", $$v)
        },
        expression: "contactPopup.isShow",
      },
    },
    [
      _c(
        "div",
        { staticStyle: { "background-color": "#ffffff" } },
        [
          _c("ContactInfoForm", {
            attrs: {
              isAuthenticated: !!_vm.userProfile,
              "user-profile": _vm.userProfile && {
                name: _vm.userProfile.name,
                email: _vm.userProfile.email,
                phone: _vm.userProfile.phone,
              },
            },
            on: {
              onCreateOrder: _vm.createContactRequest,
              onRequestSignIn: function ($event) {
                return _vm.$authPopup.show("login")
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }