import Popup from '@/global/BasePopup.vue'
const popup = {
  data: {
    show: false,
    title: '',
    message: '',
    type: 'error',
    text: '',
    hideOk: true,
    done() {},
    cancel() {}
  },
  close() {
    Object.assign(this.data, {show: false, message: ''})
  },
  error(msg, options = {}) {
    this.data.type = 'error'
    this.data.show = true
    this.data.message = msg
    this.data.title = options.title || '$vuetify.MESSAGES.POPUP_DEFAULT_TITLE.ERROR'
  },
  success(msg, options = {}) {
    this.data.type = 'success'
    this.data.show = true
    this.data.message = msg
    this.data.title = options.title || '$vuetify.MESSAGES.POPUP_DEFAULT_TITLE.SUCCESS'
  },
  warn(msg, options = {}) {
    this.data.type = 'warning'
    this.data.show = true
    this.data.message = msg
    this.data.title = options.title || '$vuetify.MESSAGES.POPUP_DEFAULT_TITLE.WARNING'
  },
  proceed(msg, options = {}) {
    this.data.type = 'proceed'
    this.data.show = true
    this.data.message = msg
    this.data.title = options.title || '$vuetify.MESSAGES.POPUP_DEFAULT_TITLE.PROCEED'
    this.data.done = options.done
  },
}

export default {
  install(Vue, pluginName = '$popup') {
    Vue.prototype[pluginName] = popup
    Vue.component('plugin-popup', Popup)
  }
}

export {popup}
