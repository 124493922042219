export default {
	MSG_ACTIVATE_SUCCESS: 'コースアクティベーションに成功しました。',
	MSG_CODE_PLACEHOLDER: 'コースのコードをご入力ください。',
	MSG_IS_OWNED_COURSE: 'あなたは既にコースを所有しています。',
	MSG_NOT_EXIST: 'アクティベーションコードは存在しないか、利用されています。',
	MSG_IS_EXPIRED: 'アクティベーションコードの有効期限が切れました。',
	MSG_CODE_CANNOT_BE_USED: 'アクティベーションコードは使用できません。',
	MSG_INVALID_ACCOUNT: 'このアカウントは無効です。',
	MSG_ACTIVATE_LOADING: 'コースのアクティブ化...',

	BTN_ACTIVATE: "直ぐアクティベート",
  TITLE: 'コースアクティベーション',
  HINT: "注：各コースは1回だけアクティベートする必要があります。",
  INPUT_PLACEHOLDER: "アクティベートコード入力、例：1233AV415",
}