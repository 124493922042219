import { RepositoryFactory } from '@/api/factory/repositoryFactory'
const UserPathRepository = RepositoryFactory.get('userPath')

const state = {
  userPath: {},
  userPaths: []
}
const actions = {
  async fetchUserPaths({ commit }, params = {}) {
    const res = await UserPathRepository.fetch(params)
    commit('setUserPaths', res.data || [])
  },
  async fetchUserPath({ commit }, params = {}) {
    const res = await UserPathRepository.fetchOne(params)
    commit('setUserPath', res.data || {})
  },
  async createUserPath({ commit, rootState }, params = {}) {
    if (!rootState.auth.authenticated || !params.path) return
    const res = await UserPathRepository.create({
      ...params,
      userId: rootState.auth.auth.id,
    })
    commit('setUserPath', res.data || {})
  }
}
const mutations = {
  setUserPaths(state, userPaths) {
    state.userPaths = userPaths
  },
  setUserPath(state, userPath) {
    state.userPath = userPath
  }
}
const getters = {
  getUserPaths(state) {
    return state.userPaths
  },
  getUserPath(state) {
    return state.userPath
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}