import questionUtils from '@/helpers/specificUtils/questionUtils'
import ratingUtils from '@/helpers/specificUtils/ratingUtils'
import validateUtils from '@/helpers/specificUtils/validateUtils'
import classroomUtils from '@/helpers/specificUtils/classroomUtils'
import classroomExamUtils from '@/helpers/specificUtils/classroomExamUtils'

export default {
  ...questionUtils,
  ...ratingUtils,
  ...validateUtils,
  ...classroomUtils,
  ...classroomExamUtils,
}
