import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_LEADERBOARD + '/leaderboard'

const create = params => {
  return Repository(baseURL).create(params)
}

const fetchWithRank = params => {
  return Repository(baseURL + '/rank').fetch(params)
}

const count = params => {
  return Repository(baseURL + '/count').fetch(params)
}

const fetchWithScore = params => {
  return Repository(baseURL + '/score').fetch(params)
}

const fetchRankStudent = (id, params) => {
  return Repository(baseURL + `/user/${id}`).fetch(params)
}

export default {
  create,
  fetchWithRank,
  fetchWithScore,
  fetchRankStudent,
  count
}
