import Repository from '@/api/core'
import queryString from 'query-string'
import vendor from '@/store/modules/vendor'
const baseURL = process.env.VUE_APP_API_LEARN + '/course'

const fetch = params => {
  const query = queryString.stringify({ ...params, vendorId: vendor.state.vendor.vendorId })
  return Repository(baseURL + `?${query}`).fetch()
}

const search = params => {
  const query = queryString.stringify({ ...params, vendorId: vendor.state.vendor.vendorId })
  return Repository(process.env.VUE_APP_API_LEARN + '/search' + `?${query}`).fetch()
}

const fetchOne = id => {
  return Repository(baseURL).fetchOne(id)
}

const fetchLayout = id => {
  return Repository(baseURL + `/layout/${id}`).fetch()
}

const create = (params) => {
  return Repository(baseURL).create(params)
}

const update = (id, params) => {
  return Repository(baseURL).update(id, params)
}

const remove = (id) => {
  return Repository(baseURL).delete(id)
}
const fetchStudentProgresReport = (params) => {
  return Repository(baseURL + '/student-progress-report').fetch({
    ...params,
  })
}
export default {
  fetchLayout,
  fetch,
  fetchOne,
  create,
  update,
  remove,
  search,
  fetchStudentProgresReport,
}
