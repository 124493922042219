import get from 'lodash-es/get'

const getDataForSubmission = ({ state, submitCount, startTime, submitTime, answersSheet, vendorId }, currentUnit, authUser) => {
  const { course, exam } = currentUnit
  const { id, name, email } = authUser
  return {
    course,
    exam,
    submitTime,
    startTime,
    state,
    submitCount,
    endTime: submitTime,
    answers: answersSheet,
    type: 'normal-test',
    vendorId: vendorId,
    userId: id,
    name,
    email
  }
}

const calculateQuestionStatistic = (submission) => {
  const totalQuestions = Object.keys(get(submission, 'questions', [])).length
  let wrongAnswersNumber = 0
  let correctAnswersNumber = 0
  let noneAnswersNumber = 0
  if (submission && submission.answers) {
    Object.keys(submission.answers).forEach(key => {
      let answer = submission.answers[key]
      if ((answer.answerId || answer.answer) && !answer.isCorrect) wrongAnswersNumber++
      if (answer.isCorrect) correctAnswersNumber++
    })
    noneAnswersNumber = totalQuestions - wrongAnswersNumber - correctAnswersNumber
  }
  const correctAnswersPercentage = Math.round((correctAnswersNumber / totalQuestions) * 100)
  const wrongAnswersPercentage = Math.round(
    (wrongAnswersNumber / totalQuestions) * 100 + correctAnswersPercentage
  )
  return {
    correctAnswersNumber,
    wrongAnswersNumber,
    noneAnswersNumber,
    correctAnswersPercentage,
    wrongAnswersPercentage
  }
}

const getTotalTimeOfParts = (parts) => {
  return Object.keys(parts).map(key => parts[key].time).reduce((pre, cur) => Number(pre) + Number(cur), 0)
}

export default {
  getDataForSubmission,
  calculateQuestionStatistic,
  getTotalTimeOfParts
}