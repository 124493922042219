import axios from 'axios'
import vendor from '@/store/modules/vendor'
import auth from '@/store/modules/auth'
import get from 'lodash-es/get'
import Repository from '@/api/core'
const vendorId = get(vendor, 'state.vendor.vendorId')
const BASE_URL = process.env.VUE_APP_API_LEARNING_PATH + '/user-path'

const fetch = (params) => {
  return Repository(BASE_URL).fetch(params)
}

const fetchOne = (params, option) => {
  return axios.get(
    BASE_URL + `/${auth.state.auth.id}`,
    { params: { ...params, vendorId } },
    option
  )
}

const create = (params, option) => {
  return Repository(BASE_URL).create(params, option)
}

export default {
  fetch,
  fetchOne,
  create,
}
