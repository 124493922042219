/**
 * this served for legacy payment,
 * which works for ebook and course (when setting is not e-commercial site)
 */

const STEP_AUTHENTICATE = {
  TITLE: '認証',
  FORM_SIGN_IN_TITLE: 'ログインしてより速く取引できます。',
  FORM_APPLY_DISCOUNT_CODE_SIGN_IN: '割引コードを使用するには、ログインする必要があります!',
  BTN_NOT_APPLY_DISCOUNT: '使用しない',
  FORM_SKIP_SIGN_IN_TITLE: 'ログインせずに支払う',
}

const STEP_PAYMENT = {
  TITLE: '支払い',
  BTN_CHECKOUT: '支払い',
  METHOD: {
    TITLE: '支払い方法',
    DIRECT_PAY: {
      TITLE: 'カウンターでの直接支払を登録する',
    },
    BANK_TRANSFER: {
      TITLE: '国内銀行振込',
      NOTE_LABEL: '振込フォーム',
      BANK_NAME_LABEL: '銀行名',
      ACCOUNT_NANE_LABEL: '口座名義',
      ACCOUNT_NUMBER_LABEL: '口座番号',
    },
    GATEWAY_TRANSFER: {
      TITLE: '{0}による支払い',
    },
  },
}

const STEP_CONFIRMATION = {
  TITLE: '確認',
  BANK_TRANSFER: {
    TITLE: 'システムは情報を確認しました。承認をお待ちください。',
    DESCRIPTION: '転送情報はできるだけ早く確認されます。ご不明な点がございましたら、ホットラインまでお問い合わせください。',
  },
  OTHER_METHOD: {
    TITLE: '登録成功',
    DESCRIPTION: 'できるだけ早く連絡いたします。ご不明な点がございましたら、サポートにお問い合わせください。',
    CONTACT_INFO_TITLE: '連絡情報',
  },
  PAYMENT_ERROR: {
    TITLE: '支払いに失敗しました。',
    DESCRIPTION: 'エラーが発生しました。もう一度やり直すか、ホットラインでカスタマーサポートにご連絡ください。',
  },
}

const INFO = {
  CHECKOUT_LABEL: '支払い:',
  ONLINE_COURSE: {
    DISCOUNT_CODE_PLACEHOLDER: '割引コードを入力してください',
    MSG_DISCOUNT_CODE_INVALID: 'この割引コードは無効です。',
    MSG_DISCOUNT_CODE_VALID: '取引コードは有効です。',
    DISCOUNT_LABEL: '(取引：-{0})',
  },
  OFFLINE_COURSE: {
    PRICE_NONE: '価格なし',
    PRICE_PER_CLASS: '{0}/コース',
  },
}

export default {
  STEP_AUTHENTICATE,
  STEP_PAYMENT,
  STEP_CONFIRMATION,
  INFO,
  MSG_CREATE_ORDER_FAIL: '発注の作成に失敗しました。',
  MSG_CREATE_ORDER_LOADING: '発注を作成しています。',
  MSG_VALIDATE_ORDER: '発注を確認しています。',
  MSG_EXISTED_PRODUCT: '{0}の商品が発注に存在しています。'
}